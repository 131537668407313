import React, { useState, useEffect } from 'react';
import { isBefore } from 'date-fns';
import { localeDate } from '~utils/dates';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import useApi, { COHORT_STATS } from '~hooks/useApi';

import LoadingSpinner from '~components/LoadingSpinner';
import MessageBox from '~components/forms/MessageBox';

import { dataFormating, calculateTotalCount, subscriptionPlanFilter } from '~utils/cohort-helpers';
import { defaultStartDate, defaultEndDate } from '~components/admin/Users/UserEvents/Utils/utils';

const AdminCohorts = () => {
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [activity, setActivity] = useState();

  const [isDataFormated, setIsDataFormated] = useState();
  const [subsPerMonth, setSubsPerMonth] = useState();
  const [memberMonthly, setMemberMonthly] = useState();
  const [memberYearly, setMemberYearly] = useState();
  const [premiumMonthly, setPremiumMonthly] = useState();
  const [premiumYearly, setPremiumYearly] = useState();
  const [educationMonthly, setEducationMonthly] = useState();
  const [educationYearly, setEducationYearly] = useState();
  const [pcMonthly, setPcMonthly] = useState();
  const [pcYearly, setPcYearly] = useState();

  const [activityCount, setActivityCount] = useState();
  const [subsPerMonthCount, setSubsPerMonthCount] = useState();
  const [memberMonthlyCount, setMemberMonthlyCount] = useState();
  const [memberYearlyCount, setMemberYearlyCount] = useState();
  const [premiumMonthlyCount, setPremiumMonthlyCount] = useState();
  const [premiumYearlyCount, setPremiumYearlyCount] = useState();
  const [educationMonthlyCount, setEducationMonthlyCount] = useState();
  const [educationYearlyCount, setEducationYearlyCount] = useState();
  const [pcMonthlyCount, setPcMonthlyCount] = useState();
  const [pcYearlyCount, setPcYearlyCount] = useState();

  function createCohortGroups(startOfTime, endOfTime) {
    const intervals = [];
    for (
      let interval = new Date(startOfTime);
      isBefore(interval, endOfTime);
      interval = interval.setDate(new Date().getDate() + 1)
    ) {
      intervals.push({
        cohort: localeDate(interval, { month: 'short', year: 'numeric' }),
        month: Number(localeDate(interval, { month: 'numeric' })),
        year: Number(localeDate(interval, { year: 'numeric' })),
        startDate: localeDate(interval),
        endDate: localeDate(interval.setMonth(interval.getMonth() + 1))
      });
    }
    return intervals;
  }

  const cohortsGroupInterval = createCohortGroups(startDate, defaultEndDate);

  const onSubmit = () => {
    const params = COHORT_STATS({ startDate, endDate });
    makeRequest(params);
    setIsDataFormated(false);
  };

  const handleStartDateChange = event => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = event => {
    setEndDate(event.target.value);
  };

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }
    if (data && !isDataFormated) {
      const { cohortsActivity, cohortsSubscriptionPerMonth, cohortMontlySubscribers } = data;

      const {
        membersMonthly,
        membersYearly,
        premiumsMonthly,
        premiumsYearly,
        educationsMonthly,
        educationsYearly,
        producersMonthly,
        producersYearly
      } = cohortMontlySubscribers && subscriptionPlanFilter(cohortMontlySubscribers);

      setActivity(dataFormating(cohortsActivity, cohortsGroupInterval));
      setSubsPerMonth(dataFormating(cohortsSubscriptionPerMonth, cohortsGroupInterval));
      setMemberMonthly(dataFormating(membersMonthly, cohortsGroupInterval));
      setMemberYearly(dataFormating(membersYearly, cohortsGroupInterval));
      setPremiumMonthly(dataFormating(premiumsMonthly, cohortsGroupInterval));
      setPremiumYearly(dataFormating(premiumsYearly, cohortsGroupInterval));
      setEducationMonthly(dataFormating(educationsMonthly, cohortsGroupInterval));
      setEducationYearly(dataFormating(educationsYearly, cohortsGroupInterval));
      setPcMonthly(dataFormating(producersMonthly, cohortsGroupInterval));
      setPcYearly(dataFormating(producersYearly, cohortsGroupInterval));

      setActivityCount(calculateTotalCount(cohortsActivity));
      setSubsPerMonthCount(calculateTotalCount(cohortsSubscriptionPerMonth));
      setMemberMonthlyCount(calculateTotalCount(membersMonthly));
      setMemberYearlyCount(calculateTotalCount(membersYearly));
      setPremiumMonthlyCount(calculateTotalCount(premiumsMonthly));
      setPremiumYearlyCount(calculateTotalCount(premiumsYearly));
      setEducationMonthlyCount(calculateTotalCount(educationsMonthly));
      setEducationYearlyCount(calculateTotalCount(educationsYearly));
      setPcMonthlyCount(calculateTotalCount(producersMonthly));
      setPcYearlyCount(calculateTotalCount(producersYearly));

      setIsDataFormated(true);
    }
  }, [isError, isLoading, data, cohortsGroupInterval, setIsDataFormated]);

  return (
    <Box p={4}>
      <Typography variant="h4">Admin Cohort Stats</Typography>
      <Box boxShadow={2} p={2} mt={2}>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Start Date"
              type="date"
              onChange={handleStartDateChange}
              defaultValue={startDate}
              name="startDate"
              InputLabelProps={{
                shrink: true
              }}
              style={{ paddingRight: '20px' }}
            />
            <TextField
              label="End Date"
              type="date"
              name="endDate"
              onChange={handleEndDateChange}
              defaultValue={endDate}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>

          <Button
            variant="outlined"
            type="submit"
            color="primary"
            disabled={isLoading}
            onClick={onSubmit}
          >
            Get Cohorts Data
          </Button>

          <Button onClick={dataFormating}>Format Data</Button>

          <Grid item xs={12}>
            {isError && <MessageBox text={error} isError />}
          </Grid>
          <Grid item xs={12}>
            {data && <h3>Total Cohorts: {data.count}</h3>}
            <h3>Activity</h3>
            {data && activity && (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Total</TableCell>
                      {cohortsGroupInterval &&
                        cohortsGroupInterval.map(item => (
                          <TableCell align="right">{item.cohort}</TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Active Cohort Members</TableCell>

                      <TableCell align="right">{activityCount}</TableCell>
                      {activity.length === cohortsGroupInterval.length &&
                        activity.map(item => (
                          <>
                            <TableCell key={activity.count} align="right">
                              {item.count}
                            </TableCell>
                          </>
                        ))}
                    </TableRow>
                    <TableRow>
                      <TableCell align="right">Active Cohort %</TableCell>
                      <TableCell align="right"> </TableCell>
                      {activity.length === cohortsGroupInterval.length &&
                        activity.map(item => (
                          <TableCell key={item.count} align="right">
                            {((item.count / activityCount) * 100).toFixed(2)}%
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <h3>Subscriptions</h3>

            {data && subsPerMonth && (
              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell />
                      <TableCell>Total</TableCell>
                      {cohortsGroupInterval &&
                        cohortsGroupInterval.map(item => (
                          <TableCell align="right">{item.cohort}</TableCell>
                        ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>Cohort Subscribers</TableCell>
                      <TableCell align="right">{subsPerMonthCount}</TableCell>
                      {subsPerMonth.length === cohortsGroupInterval.length &&
                        subsPerMonth.map(item => (
                          <>
                            <TableCell key={item.count} align="right">
                              {item.count}
                            </TableCell>
                          </>
                        ))}
                    </TableRow>
                    <TableRow>
                      <TableCell>Cohort Subscribers %</TableCell>
                      <TableCell align="right"> </TableCell>
                      {subsPerMonth.length === cohortsGroupInterval.length &&
                        subsPerMonth.map(item => (
                          <TableCell key={item.count} align="right">
                            {((item.count / subsPerMonthCount) * 100).toFixed(2)}%
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
            <h3>Subscription Plans</h3>
            {data &&
              memberMonthly &&
              memberYearly &&
              premiumMonthly &&
              premiumYearly &&
              educationMonthly &&
              educationYearly &&
              pcMonthly &&
              pcYearly && (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell> </TableCell>
                        <TableCell>Total</TableCell>
                        {cohortsGroupInterval &&
                          cohortsGroupInterval.map(item => (
                            <TableCell align="right">{item.cohort}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="right">Member Monthly</TableCell>
                        <TableCell align="right">{memberMonthlyCount}</TableCell>
                        {memberMonthly.length === cohortsGroupInterval.length &&
                          memberMonthly.map(item => (
                            <>
                              <TableCell key={item.count} align="right">
                                {item.count}
                              </TableCell>
                            </>
                          ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">Member Yearly</TableCell>
                        <TableCell align="right">{memberYearlyCount}</TableCell>
                        {memberYearly.length === cohortsGroupInterval.length &&
                          memberYearly.map(item => (
                            <>
                              <TableCell key={item.count} align="right">
                                {item.count}
                              </TableCell>
                            </>
                          ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">Premium Monthly</TableCell>
                        <TableCell align="right">{premiumMonthlyCount}</TableCell>
                        {premiumMonthly.length === cohortsGroupInterval.length &&
                          premiumMonthly.map(item => (
                            <>
                              <TableCell key={item.count} align="right">
                                {item.count}
                              </TableCell>
                            </>
                          ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">Premium Yearly</TableCell>
                        <TableCell align="right">{premiumYearlyCount}</TableCell>
                        {premiumYearly.length === cohortsGroupInterval.length &&
                          premiumYearly.map(item => (
                            <>
                              <TableCell key={item.count} align="right">
                                {item.count}
                              </TableCell>
                            </>
                          ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">Educator Monthly</TableCell>

                        <TableCell align="right">{educationMonthlyCount}</TableCell>
                        {educationMonthly.length === cohortsGroupInterval.length &&
                          educationMonthly.map(item => (
                            <>
                              <TableCell key={item.count} align="right">
                                {item.count}
                              </TableCell>
                            </>
                          ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">Educator Yearly</TableCell>

                        <TableCell align="right">{educationYearlyCount}</TableCell>
                        {educationYearly.length === cohortsGroupInterval.length &&
                          educationYearly.map(item => (
                            <>
                              <TableCell key={item.count} align="right">
                                {item.count}
                              </TableCell>
                            </>
                          ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">Patrons Circle Monthly</TableCell>

                        <TableCell align="right">{pcMonthlyCount}</TableCell>
                        {pcMonthly.length === cohortsGroupInterval.length &&
                          pcMonthly.map(item => (
                            <>
                              <TableCell key={item.count} align="right">
                                {item.count}
                              </TableCell>
                            </>
                          ))}
                      </TableRow>
                      <TableRow>
                        <TableCell align="right">Patrons Circle Yearly</TableCell>

                        <TableCell align="right">{pcYearlyCount}</TableCell>
                        {pcYearly.length === cohortsGroupInterval.length &&
                          pcYearly.map(item => (
                            <>
                              <TableCell key={item.count} align="right">
                                {item.count}
                              </TableCell>
                            </>
                          ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
          </Grid>
        </Grid>

        {isLoading && <LoadingSpinner textAlign="center" />}
      </Box>
    </Box>
  );
};

export default AdminCohorts;
