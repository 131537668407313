import { formatDateYYYYMMDD } from '~utils/dates';

export const getUniqueValues = arr => Array.from(new Set(arr));
const startDate = new Date().setDate(new Date().getDate() - 7);
export const defaultStartDate = formatDateYYYYMMDD(startDate);
export const defaultEndDate = formatDateYYYYMMDD(new Date());
export const calcPage = (data, rowsPerPage, page) =>
  rowsPerPage > 0 ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : data;
export const immutableSort = (arr, cb) => JSON.parse(JSON.stringify(arr)).sort(cb);
export const eventSort = (item1, item2) => (item1.event > item2.event ? 1 : -1);
export const dateSort = (item1, item2) =>
  new Date(item1.created_at) > new Date(item2.created_at) ? 1 : -1;
export const switchOrder = prev => (prev === 'asc' ? 'desc' : 'asc');
