import React, { useEffect } from 'react';

import useApi from '~hooks/useApi/useApi';
import { ADMIN_GET_ACCOUNT_DELETION_REQUESTS } from '~hooks/useApi';
import { AccountDeletionRequests as AccountDeletionRequestsComponent } from '~components/admin/Dashboard/components/AccountDeletionRequests';

const AccountDeletionRequests = () => {
  const [{ isLoading, isError, data, error }, makeRequest] = useApi();

  useEffect(() => {
    const adminDashboardParams = ADMIN_GET_ACCOUNT_DELETION_REQUESTS();
    makeRequest(adminDashboardParams);
  }, [makeRequest]);

  return (
    <AccountDeletionRequestsComponent
      isLoading={isLoading}
      isError={isError}
      error={error}
      requests={data || []}
    />
  );
};

AccountDeletionRequests.propTypes = {};

export default AccountDeletionRequests;
