import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles, TextField } from '@material-ui/core';
import PropTypes from 'prop-types';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import { useParams } from '@reach/router';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';

const useStyles = makeStyles({
  container: {
    // Weirdly this adds margin at the top of the Grid due to spacing ¯\_(ツ)_/¯
    marginTop: 0,
    padding: '0 20px'
  }
});

const UserEventsTopBar = ({ dates, handlers, eventList, onSelectEvent }) => {
  const { id: userId } = useParams();
  const { startDate, endDate, defaultEndDate } = dates;
  const { handleStartDate, handleEndDate, requestData } = handlers;
  const [anchorEl, setAnchorEl] = useState(null);
  const removeMenuEl = () => setAnchorEl(null);
  const setMenuEl = e => setAnchorEl(e.currentTarget);
  const classes = useStyles();
  return (
    <Grid
      justify="flex-end"
      alignItems="center"
      spacing={3}
      container
      className={classes.container}
    >
      {eventList.length !== 0 && (
        <Grid item>
          <ButtonSubmit onClick={setMenuEl}> Filter </ButtonSubmit>
          <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={removeMenuEl}>
            {eventList.map(event => (
              <MenuItem key={`${event}menuOption`}>
                <Checkbox color="primary" onChange={onSelectEvent(event)} />
                {event}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
      <Grid item>
        <TextField
          label="Start Date"
          type="date"
          onChange={handleStartDate}
          defaultValue={startDate}
          name="startDate"
          InputProps={{ inputProps: { max: endDate } }}
        />
      </Grid>
      <Grid item>
        <TextField
          label="End Date"
          type="date"
          name="endDate"
          onChange={handleEndDate}
          defaultValue={endDate}
          InputProps={{ inputProps: { max: defaultEndDate } }}
        />
      </Grid>
      <Grid item>
        <ButtonSubmit onClick={requestData(startDate, endDate, userId)}>Submit</ButtonSubmit>
      </Grid>
    </Grid>
  );
};

UserEventsTopBar.propTypes = {
  dates: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
    defaultEndDate: PropTypes.string.isRequired
  }).isRequired,
  handlers: PropTypes.shape({
    handleStartDate: PropTypes.func.isRequired,
    handleEndDate: PropTypes.func.isRequired,
    requestData: PropTypes.func.isRequired
  }).isRequired,
  eventList: PropTypes.arrayOf(PropTypes.string).isRequired,
  onSelectEvent: PropTypes.func.isRequired
};

export default UserEventsTopBar;
