import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/styles';
import { ButtonSubmit } from '~components/form-buttons';
import Link from '~components/Link';
import { calcPage } from '../Utils/utils';

const useStyles = makeStyles({
  expanded: { whiteSpace: 'pre-wrap' }
});

const UserEventsEntry = ({ eventObj }) => {
  const classes = useStyles();
  const [isClicked, setIsClicked] = useState(false);
  const handle = () => {
    setIsClicked(prev => !prev);
  };
  const { event, created_at, result, device_client } = eventObj;

  const { playlistId, videoId } = eventObj?.properties;

  return (
    <>
      <TableRow>
        <TableCell>{event}</TableCell>
        <TableCell>{created_at}</TableCell>
        <TableCell>{result}</TableCell>
        <TableCell>{device_client}</TableCell>
        <TableCell>
          <ButtonSubmit onClick={handle}>Expand</ButtonSubmit>
        </TableCell>
      </TableRow>
      {isClicked && (
        <TableRow>
          <TableCell className={classes.expanded}>
            {JSON.stringify(eventObj, null, 1)}
            <br />
            {playlistId && (
              <Link
                to={`https://aleph-beta.prismic.io/documents~k=playlist&b=working&c=published&l=en-us/${playlistId}/`}
              >
                To Prismic Playlist
              </Link>
            )}
            <br />
            {videoId && (
              <Link
                to={`https://aleph-beta.prismic.io/documents~k=video&b=working&c=published&l=en-us/${videoId}/`}
              >
                To Prismic Video
              </Link>
            )}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

UserEventsEntry.propTypes = {
  eventObj: PropTypes.shape({
    id: PropTypes.number,
    user_id: PropTypes.number,
    device_ip_address: PropTypes.string,
    device_client: PropTypes.string,
    device_user_agent: PropTypes.string,
    event: PropTypes.string,
    result: PropTypes.string,
    result_code: PropTypes.number,
    properties: PropTypes.shape({}),
    session: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    fingerprint: PropTypes.string
  }).isRequired
};

const UserEventsTableBody = ({ data, rowsPerPage, page }) => (
  <TableBody>
    {calcPage(data, rowsPerPage, page).map(event => (
      <UserEventsEntry eventObj={event} key={event.id} />
    ))}
  </TableBody>
);

export default UserEventsTableBody;

UserEventsTableBody.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user_id: PropTypes.number,
      device_ip_address: PropTypes.string,
      device_client: PropTypes.string,
      device_user_agent: PropTypes.string,
      event: PropTypes.string,
      result: PropTypes.string,
      result_code: PropTypes.number,
      properties: PropTypes.shape({}),
      session: PropTypes.string,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
      fingerprint: PropTypes.string
    }).isRequired
  ).isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired
};
