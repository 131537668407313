import React from 'react';
import { navigate, Link as GatsbyLink } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTable, { MTableToolbar } from 'material-table';
import Button from '@material-ui/core/Button';
import Edit from '@material-ui/icons/Edit';

import request from '~utils/request';
import { ADMIN_GET_USER_LIST } from '~hooks/useApi';
import { getRequiredPlanName } from '~utils/required-subscription-plan';

import SubscriptionDescription from '~components/admin/Users/SubscriptionDescription';
import DetailPanel from '~components/admin/Users/DetailPanel';

import tableIcons from '~components/admin/tableIcons';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: 0
  },
  actionButtonsWrapper: {
    top: '15px',
    position: 'absolute',
    right: '290px',
    [theme.breakpoints.only('xs')]: {
      top: 'unset',
      position: 'unset',
      right: 'unset'
    },
    display: 'flex'
  },
  actionButton: {
    marginLeft: '16px'
  }
}));

const Users = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <MaterialTable
        icons={tableIcons}
        title="Users"
        components={{
          Toolbar: props => (
            <>
              <MTableToolbar {...props} />
              <div className={classes.actionButtonsWrapper}>
                <Button
                  component={GatsbyLink}
                  to="/admin/users-add-bulk"
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                >
                  Bulk Add
                </Button>
                <Button
                  component={GatsbyLink}
                  to="/admin/users-add"
                  variant="contained"
                  color="primary"
                  className={classes.actionButton}
                >
                  Add User
                </Button>
              </div>
            </>
          )
        }}
        columns={[
          { title: 'Id', field: 'id' },
          { title: 'Username', field: 'username' },
          {
            title: 'Access Level',
            field: 'access_level',
            render: rowData => getRequiredPlanName(rowData.access_level)
          },
          {
            title: 'Subscription',
            field: 'subscription_plan_id',
            render: rowData => <SubscriptionDescription {...rowData} />
          },
          { title: 'Notes', field: 'notes' }
        ]}
        data={query =>
          request(ADMIN_GET_USER_LIST({ search: query.search, page: query.page + 1 }))
            .then(response => ({
              data: response.data.users,
              page: response.data.page - 1,
              totalCount: Number(response.data.count)
            }))
            .catch(error => {
              console.error(error); // eslint-disable-line
            })
        }
        detailPanel={rowData => <DetailPanel rowData={rowData} />}
        actions={[
          () => ({
            icon: () => <Edit />,
            tooltip: 'Edit User',
            onClick: (event, rowData) => navigate(`/admin/users-edit/${rowData.id}`) // eslint-disable-line
          })
        ]}
        options={{
          actionsColumnIndex: -1,
          debounceInterval: 750,
          pageSize: 20,
          pageSizeOptions: [20]
        }}
        localization={{
          header: {
            actions: 'Edit'
          }
        }}
      />
    </div>
  );
};

export default Users;
