import React, { useEffect, useReducer } from 'react';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import PropTypes from 'prop-types';
import MessageBox from '~components/forms/MessageBox';
import LoadingSpinner from '~components//LoadingSpinner';
import UserTableFooter from './UserEventsPagination';
import UserTableBody from './UserEventsBody';
import UserTableHead from './UserEventsHead';
import { immutableSort, eventSort, dateSort, switchOrder } from '../Utils/utils';
import { initState, reducer } from '../Reducer/userEventsTableReducer';

const UserEventsTable = ({ data, isLoading, isError, error }) => {
  const headData = ['Events', 'Date', 'Result', 'Client'];
  const [state, dispatch] = useReducer(reducer, initState(data));
  const { page, order, orderBy, rowsPerPage, appData } = state;

  const handleChangePage = (_, newPage) => {
    dispatch({ type: 'page', payload: newPage });
  };

  const handleChangeRowsPerPage = event => {
    dispatch({ type: 'rowsPerPage', payload: parseInt(event.target.value, 10) });
  };

  const sortHandler = event => () => {
    const sort = event === 'Events' ? eventSort : dateSort;
    const getEvent = event === 'Events' ? 'Events' : 'Date';
    const newData = immutableSort(appData, sort);

    if (order === 'desc') newData.reverse();
    if (orderBy !== getEvent) dispatch({ type: 'orderBy', payload: getEvent });

    dispatch({ type: 'appData', payload: newData });
    dispatch({ type: 'order', payload: switchOrder });
  };

  useEffect(() => {
    dispatch({ type: 'appData', payload: data });
  }, [data]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <MessageBox text={error} isError />;
  }

  return (
    <TableContainer>
      <Table>
        <UserTableHead
          data={headData}
          orderBy={orderBy}
          direction={order}
          sortHandler={sortHandler}
        />
        <UserTableBody data={appData} rowsPerPage={rowsPerPage} page={page} />
        <UserTableFooter
          data={appData}
          page={page}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPage={rowsPerPage}
        />
      </Table>
    </TableContainer>
  );
};

UserEventsTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user_id: PropTypes.number,
      device_ip_address: PropTypes.string,
      device_client: PropTypes.string,
      device_user_agent: PropTypes.string,
      event: PropTypes.string,
      result: PropTypes.string,
      result_code: PropTypes.number,
      properties: PropTypes.shape({}),
      session: PropTypes.string,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
      fingerprint: PropTypes.string
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.string
};

UserEventsTable.defaultProps = {
  error: ''
};

export default UserEventsTable;
