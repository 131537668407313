/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { localeDate } from '~utils/dates';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import AccessLevelLabel from '~components/access-level-label/AccessLevelLabel';
import products, { getProductPriceText } from '~utils/products';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: 0
  },
  card: {
    height: '100%'
  }
}));

const DetailPanel = ({ rowData }) => {
  const classes = useStyles();
  const priceText = useMemo(() => {
    const product = products.find(({ id }) => id === rowData.subscription_plan_id);
    const isYearly = rowData.subscription_plan_period === 'year';
    return getProductPriceText(product, isYearly);
  }, [rowData]);

  return (
    <div style={{ backgroundColor: '#f4f6f8', overflow: 'hidden' }}>
      <Grid container spacing={4} className={classes.root}>
        <Grid item xs={7} sm={4}>
          <Card className={classes.card}>
            <CardHeader title="Info" titleTypographyProps={{ variant: 'body1' }} />
            <Divider />
            <CardContent className={classes.content}>
              <Table className={classes.table} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      First Name
                    </TableCell>
                    <TableCell>{rowData.meta_first_name}</TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#F4F6F8' }}>
                    <TableCell component="th" scope="row">
                      Last Name
                    </TableCell>
                    <TableCell>{rowData.meta_last_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Phone #
                    </TableCell>
                    <TableCell>{rowData.meta_phone}</TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#F4F6F8' }}>
                    <TableCell component="th" scope="row">
                      Age
                    </TableCell>
                    <TableCell>{rowData.meta_age}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Address
                    </TableCell>
                    <TableCell>
                      {rowData.meta_address} <br /> {rowData.meta_city} {rowData.meta_zip}{' '}
                      {rowData.meta_country}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
            <Divider />
          </Card>
        </Grid>
        <Grid item xs={7} sm={4}>
          <Card className={classes.card}>
            <CardHeader title="Subscription" titleTypographyProps={{ variant: 'body1' }} />
            <Divider />
            <CardContent className={classes.content}>
              <Table className={classes.table} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Access Level
                    </TableCell>
                    <TableCell>
                      <div style={{ maxWidth: '100px' }}>
                        <AccessLevelLabel
                          access_level={rowData.access_level}
                          is_admin={rowData.is_admin}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Plan
                    </TableCell>
                    <TableCell>
                      <Box m={0} maxWidth="100px">
                        {priceText}
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#F4F6F8' }}>
                    <TableCell component="th" scope="row">
                      Period
                    </TableCell>
                    <TableCell style={{ textTransform: 'capitalize' }}>
                      {rowData.subscription_plan_period
                        ? `${rowData.subscription_plan_period}ly`
                        : 'No Subscription'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Gateway
                    </TableCell>
                    <TableCell>
                      {rowData.subscription_gateway_stripe_id
                        ? 'Stripe'
                        : rowData.subscription_gateway_paypal_id
                        ? 'PayPal'
                        : rowData.subscription_gateway_appstore_id
                        ? 'Apple App Store'
                        : rowData.subscription_gateway_gplay_id
                        ? 'Google Play'
                        : 'No Gateway'}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#F4F6F8' }}>
                    <TableCell component="th" scope="row">
                      Joined
                    </TableCell>
                    <TableCell>
                      {rowData.subscription_expires_at
                        ? localeDate(rowData.subscription_joined_at)
                        : 'No Subscription'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Upgraded
                    </TableCell>
                    <TableCell>
                      {rowData.subscription_expires_at
                        ? localeDate(rowData.subscription_upgraded_at)
                        : 'No Upgrades'}
                    </TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#F4F6F8' }}>
                    <TableCell component="th" scope="row">
                      Expiration
                    </TableCell>
                    <TableCell>
                      {rowData.subscription_expires_at
                        ? localeDate(rowData.subscription_expires_at)
                        : 'No Subscription'}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Cancelled
                    </TableCell>
                    <TableCell>
                      {rowData.subscription_expires_at
                        ? localeDate(rowData.subscription_cacelled_at)
                        : 'No Cancellations'}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
            <Divider />
          </Card>
        </Grid>
        <Grid item xs={7} sm={4}>
          <Card className={classes.card}>
            <CardHeader title="Miscellaneous" titleTypographyProps={{ variant: 'body1' }} />
            <Divider />
            <CardContent className={classes.content}>
              <Table className={classes.table} size="small">
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Bar &amp; Bat Mitzvah Access
                    </TableCell>
                    <TableCell>{rowData.has_bar_mitzvah_access ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#F4F6F8' }}>
                    <TableCell component="th" scope="row">
                      Bar &amp; Bat Mitzvah Onboarded
                    </TableCell>
                    <TableCell>{rowData.has_bar_mitzvah_onboarding ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Newsletter
                    </TableCell>
                    <TableCell>{rowData.is_newsletter_active ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow style={{ backgroundColor: '#F4F6F8' }}>
                    <TableCell component="th" scope="row">
                      Verified
                    </TableCell>
                    <TableCell>{rowData.is_verified ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Failed Charges
                    </TableCell>
                    <TableCell>{rowData.has_failed_charges ? 'Yes' : 'No'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
            <Divider />
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

DetailPanel.propTypes = {
  rowData: PropTypes.shape().isRequired
};

export default DetailPanel;
