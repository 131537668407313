export const initState = data => ({
  orderBy: 'Date',
  order: 'asc',
  page: 0,
  rowsPerPage: 50,
  appData: data
});

export const reducer = (state, action) => {
  switch (action.type) {
    case 'orderBy':
      return {
        ...state,
        orderBy: action.payload
      };
    case 'order':
      if (typeof action.payload === 'function') {
        return {
          ...state,
          order: action.payload(state.order)
        };
      }

      return {
        ...state,
        order: action.payload
      };

    case 'page':
      return {
        ...state,
        page: action.payload
      };
    case 'rowsPerPage':
      return {
        ...state,
        rowsPerPage: action.payload
      };
    case 'appData':
      return {
        ...state,
        appData: action.payload
      };
    default:
      return state;
  }
};
