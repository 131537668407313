import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '155px'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.error.dark
  },
  differenceValue: {
    color: theme.palette.error.dark,
    marginRight: theme.spacing(1)
  }
}));

const Subscriptions = ({ subscriptions, ...rest }) => {
  const classes = useStyles();

  const isSubsPositive = Math.sign(parseInt(subscriptions.change, 10)) === 1;

  function roundIt(value, decimals) {
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  }

  return (
    <Card {...rest} className={classes.root}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TOTAL SUBSCRIPTIONS
            </Typography>
            <Typography variant="h3">{subscriptions.total}</Typography>
          </Grid>

          <Grid item>
            <Avatar className={classes.avatar}>
              <AttachMoneyIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>

        <div className={classes.difference}>
          {isSubsPositive ? (
            <ArrowUpwardIcon />
          ) : (
            <ArrowDownwardIcon className={classes.differenceIcon} />
          )}

          <Typography
            className={!isSubsPositive ? classes.differenceValue : undefined}
            variant="body2"
          >
            {roundIt(subscriptions.percent, 2)}%&nbsp;&nbsp;
          </Typography>

          <Typography className={classes.caption} variant="caption">
            Last 30 Days
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

Subscriptions.propTypes = {
  subscriptions: PropTypes.shape({
    total: PropTypes.string,
    percent: PropTypes.number,
    change: PropTypes.string
  }).isRequired
};

export default Subscriptions;
