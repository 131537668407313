import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '155px',
    backgroundColor: theme.palette.error.main,
    color: theme.palette.primary.contrastText
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: 'white',
    color: theme.palette.error.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  }
}));

const TotalFailed = ({ failedCharges, ...rest }) => {
  const classes = useStyles();

  return (
    <Card {...rest} className={classes.root}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography className={classes.title} color="inherit" gutterBottom variant="body2">
              TOTAL FAILED CHARGES
            </Typography>
            <Typography color="inherit" variant="h3">
              {failedCharges.total}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PriorityHighIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

TotalFailed.propTypes = {
  failedCharges: PropTypes.shape({
    total: PropTypes.number
  }).isRequired
};

export default TotalFailed;
