import React from 'react';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import PropTypes from 'prop-types';

const EventTableHead = ({ sortHandler, data, orderBy, direction }) => (
  <TableHead>
    <TableRow>
      {data.map(item => (
        <TableCell key={`${item}Head`}>
          {item === 'Events' || item === 'Date' ? (
            <TableSortLabel
              onClick={sortHandler(item)}
              active={orderBy === item}
              direction={direction}
            >
              {item}
            </TableSortLabel>
          ) : (
            item
          )}
        </TableCell>
      ))}
    </TableRow>
  </TableHead>
);
EventTableHead.propTypes = {
  sortHandler: PropTypes.func.isRequired,
  data: PropTypes.arrayOf(PropTypes.string).isRequired,
  orderBy: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired
};

export default EventTableHead;
