import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import TablePagination from '@material-ui/core/TablePagination';
import InputAdornment from '@material-ui/core/InputAdornment';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { useForm } from 'react-hook-form';

import LoadingSpinner from '~components/LoadingSpinner';
import TextInput from '~components/form-inputs/TextInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import Comment from '~components/comments/Comment';

import useApi, {
  ADMIN_GET_ALL_COMMENTS,
  COMMENTS_EDIT,
  COMMENTS_REPORT,
  ADMIN_ADD_COMMENT,
  ADMIN_DELETE_COMMENT
} from '~hooks/useApi';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  sep: {
    margin: '30px 0'
  },
  paginationContainer: {
    display: 'flex'
  },
  pagination: {
    flex: 1
  },
  snackbarStyle: {
    backgroundColor: '#43a047'
  }
}));

const AdminComments = () => {
  const classes = useStyles();
  const [{ isLoading, data }, makeRequest] = useApi();
  const [commentSubmitState, makeCommentSubmitRequest] = useApi();
  const [searchParams, setSearchParams] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { handleSubmit, register } = useForm();

  const handleSearchSubmit = searchParam => {
    setSearchParams(searchParam.search);
  };
  const handleSnackbarClose = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const adminCommentsParams = ADMIN_GET_ALL_COMMENTS({ search: searchParams, page: 1 });
    makeRequest(adminCommentsParams);

    if (commentSubmitState && commentSubmitState.data) {
      setIsOpen(true);
      commentSubmitState.data = null;
    }
  }, [makeRequest, searchParams, commentSubmitState]); //eslint-disable-line

  const handleCommentSubmit = ({ playlistId, videoId, threadId, comment }) => {
    const commentSubmitParams = ADMIN_ADD_COMMENT({ playlistId, videoId, threadId, comment });
    makeCommentSubmitRequest(commentSubmitParams);
  };

  const handleCommentEdit = ({ commentId, comment, playlistUid }) => {
    const commentEditParams = COMMENTS_EDIT({ commentId, playlistUid, comment });
    makeRequest(commentEditParams);
  };

  const handleReportSubmit = ({ commentId }) => {
    const commentReportParams = COMMENTS_REPORT({ commentId });
    makeRequest(commentReportParams);
  };

  const handleDeleteSubmit = ({ commentId }) => {
    const commentDeleteParams = ADMIN_DELETE_COMMENT({ commentId });
    makeRequest(commentDeleteParams);
  };

  const handleChangePage = (event, newPage) => {
    const adminCommentsParams = ADMIN_GET_ALL_COMMENTS({ search: searchParams, page: newPage + 1 });
    makeRequest(adminCommentsParams);
  };

  return (
    <>
      <div className={classes.root}>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <Grid item xs={12} md={7}>
            <Typography variant="h4">Comments</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <form onSubmit={handleSubmit(handleSearchSubmit)}>
              <TextInput
                label="Search by keyword"
                name="search"
                inputRef={register}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <ButtonSubmit>Search</ButtonSubmit>
                    </InputAdornment>
                  )
                }}
              />
            </form>
          </Grid>
        </Grid>

        {isLoading && <LoadingSpinner />}

        {data && (
          <Paper className={classes.root}>
            {data.comments &&
              data.comments.map(comment => (
                <React.Fragment key={comment.id}>
                  <Comment
                    comment={comment}
                    handleCommentSubmit={handleCommentSubmit}
                    handleCommentEdit={handleCommentEdit}
                    handleReportSubmit={handleReportSubmit}
                    handleDeleteSubmit={handleDeleteSubmit}
                  />
                  <Divider className={classes.sep} />
                </React.Fragment>
              ))}
            <div className={classes.paginationContainer}>
              <TablePagination
                component="nav"
                page={data && data.page - 1}
                rowsPerPage={20}
                rowsPerPageOptions={[20]}
                count={data && +data.count}
                onChangePage={handleChangePage}
                className={classes.pagination}
              />
            </div>
          </Paper>
        )}
        <Snackbar open={isOpen} autoHideDuration={6000} onClose={handleSnackbarClose}>
          <SnackbarContent
            className={classes.snackbarStyle}
            message={<span>Your comment has been submitted successfully!</span>}
            action={[
              <IconButton
                key="close"
                aria-label="close"
                color="inherit"
                onClick={handleSnackbarClose}
              >
                <CloseIcon />
              </IconButton>
            ]}
          />
        </Snackbar>
      </div>
    </>
  );
};

export default AdminComments;
