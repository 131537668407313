const defaultData = (year, month) => ({
  count: '0',
  month,
  year
});

export const dataFormating = (dataset, formater) => {
  const dataCopy = [...dataset];
  if (dataCopy && formater && formater.length > 0) {
    for (let i = 0; i < formater.length; i += 1) {
      if (
        !dataCopy[i] ||
        formater[i].month !== dataCopy[i].month ||
        formater[i].year !== dataCopy[i].year
      ) {
        dataCopy.splice(i, 0, defaultData(formater[i].year, formater[i].month));
      }
    }
  }

  return dataCopy;
};

export const calculateTotalCount = array => {
  const itemCount = array.map(item => Number(item.count)).reduce((a, b) => a + b, 0);
  return itemCount;
};

export const subscriptionPlanFilter = dataState => {
  const dataFilter = [...dataState];

  const membersMonthly = dataFilter.filter(
    subscription =>
      (subscription.plan_id === 1 || subscription.plan_id_alternate === 1) &&
      (subscription.plan_period === 'month' || subscription.plan_period_alternate === 'month')
  );
  const membersYearly = dataFilter.filter(
    subscription =>
      (subscription.plan_id === 1 || subscription.plan_id_alternate === 1) &&
      (subscription.plan_period === 'year' || subscription.plan_period_alternate === 'year')
  );
  const premiumsMonthly = dataFilter.filter(
    subscription =>
      (subscription.plan_id === 2 || subscription.plan_id_alternate === 2) &&
      (subscription.plan_period === 'month' || subscription.plan_period_alternate === 'month')
  );
  const premiumsYearly = dataFilter.filter(
    subscription =>
      (subscription.plan_id === 2 || subscription.plan_id_alternate === 2) &&
      (subscription.plan_period === 'year' || subscription.plan_period_alternate === 'year')
  );
  const educationsMonthly = dataFilter.filter(
    subscription =>
      (subscription.plan_id === 3 || subscription.plan_id_alternate === 3) &&
      (subscription.plan_period === 'month' || subscription.plan_period_alternate === 'month')
  );
  const educationsYearly = dataFilter.filter(
    subscription =>
      (subscription.plan_id === 3 || subscription.plan_id_alternate === 3) &&
      (subscription.plan_period === 'year' || subscription.plan_period_alternate === 'year')
  );
  const producersMonthly = dataFilter.filter(
    subscription =>
      (subscription.plan_id === 4 || subscription.plan_id_alternate === 4) &&
      (subscription.plan_period === 'month' || subscription.plan_period_alternate === 'month')
  );
  const producersYearly = dataFilter.filter(
    subscription =>
      (subscription.plan_id === 4 || subscription.plan_id_alternate === 4) &&
      (subscription.plan_period === 'year' || subscription.plan_period_alternate === 'year')
  );
  return {
    membersMonthly,
    membersYearly,
    premiumsMonthly,
    premiumsYearly,
    educationsMonthly,
    educationsYearly,
    producersMonthly,
    producersYearly
  };
};
