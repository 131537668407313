import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import useApi, { ADMIN_CHECK_PREMIUM_USERS } from '~hooks/useApi';
import LoadingSpinner from '~src/components/LoadingSpinner';
import AdminUserPremiumCheckForm from '~src/components/admin/Users/UserPremiumCheckForm';

// NOTE: keys are EXACT matches for `backend/src/utils/checkUserPremiumStatus.ts::UserPremiumStatusCheckCode` enum values.
const COLOR_MAP = {
  'Do not pay': 'orange',
  'Not privileged': 'red',
  Missing: 'yellow'
};

const ResultsTable = ({ results }) => (
  <table>
    <thead>
      <tr>
        <th>Username</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {results && results.length ? (
        results.map(({ username, result }) => (
          <tr style={{ backgroundColor: COLOR_MAP[result] || 'transparent' }}>
            <td>{username}</td>
            <td>{result}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={2}>No results</td>
        </tr>
      )}
    </tbody>
  </table>
);

ResultsTable.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({ username: PropTypes.string, result: PropTypes.string })
  )
};
ResultsTable.defaultProps = {
  results: []
};

const AdminUserPremiumCheck = () => {
  const { register, handleSubmit } = useForm();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  const onSubmit = userData => {
    const { usernames } = userData;
    const params = ADMIN_CHECK_PREMIUM_USERS({ usernames });
    makeRequest(params);
  };

  return (
    <>
      <h1>Facebook Premium Group Check</h1>
      <p>
        This text field allows you to check user accounts in bulk whether they are <em>paying</em>{' '}
        <em>premium</em> members. Enter the emails of the users, one email per line. If you copy the
        list of emails from some sort of spreadsheet column and paste it to the text field it would
        be enough.
      </p>
      <p>
        RF is going live in the closed premium Facebook group weekly, CH is trying to keep it
        up-to-date with only active legitimate members.
      </p>
      <AdminUserPremiumCheckForm
        handlers={{
          onSubmit,
          handleSubmit
        }}
        register={register}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
      {isLoading ? (
        <>
          <LoadingSpinner />
          Waiting for the processing to end...
        </>
      ) : (
        <ResultsTable results={data && data.results} />
      )}
    </>
  );
};

export default AdminUserPremiumCheck;
