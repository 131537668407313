import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import Popups from '~layout/Popups';

import PopupsUserFlow from '~components/admin/Dashboard/components/PopupsUserFlow/PopupsUserFlow';
import { POPUP_USER_FLOW_DATA } from '~utils/popup-helpers';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Admin = () => {
  const classes = useStyles();
  const [popupNum, setPopupNum] = useState(0);

  const showPopup = popup => {
    setPopupNum(popup);
  };

  return (
    <div className={classes.root}>
      <Popups which={popupNum} setPopupId={setPopupNum} showTopCloseBtn />
      <Grid container spacing={4}>
        {POPUP_USER_FLOW_DATA &&
          POPUP_USER_FLOW_DATA.map(popup => (
            <Grid key={popup.title} item lg={4} md={6} xl={3} xs={12}>
              <PopupsUserFlow showPopup={showPopup} {...popup} />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default Admin;
