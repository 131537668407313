import React from 'react';

import { useForm } from 'react-hook-form';

import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import useApi, { ADMIN_VIDEO_INFORMATION } from '~hooks/useApi';

import TextInput from '~components/form-inputs/TextInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import LoadingSpinner from '~components/LoadingSpinner';
import MessageBox from '~components/forms/MessageBox';

const VideoInformation = () => {
  const { register, handleSubmit } = useForm();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  const onSubmit = videoId => {
    const { wistia_id } = videoId;
    const params = ADMIN_VIDEO_INFORMATION({ videoId: wistia_id });
    makeRequest(params);
  };

  return (
    <Box p={4}>
      <Typography variant="h4">Video Information</Typography>

      <Box boxShadow={2} p={2} mt={2}>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} md={5}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextInput label="Wistia ID" name="wistia_id" inputRef={register} />
              <ButtonSubmit>Get Info</ButtonSubmit>
            </form>
          </Grid>

          <Grid item xs={12} md={8}>
            {isError && <MessageBox text={error} isError />}

            {isLoading && <LoadingSpinner textAlign="left" />}

            {data && (
              <>
                <Typography variant="subtitle1">Duration: {data.duration}</Typography>
                <Typography variant="subtitle1">
                  Wistia Stream URL: {data.wistiaStreamUrl}
                </Typography>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default VideoInformation;
