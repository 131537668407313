import React from 'react';
import PropTypes from 'prop-types';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { CSVLink } from 'react-csv';
import { makeStyles } from '@material-ui/styles';
import { ButtonSubmit } from '~src/components/form-buttons';

const useStyles1 = makeStyles({
  btn: {
    textDecoration: 'none',
    color: 'white'
  }
});
const useStyles2 = makeStyles({
  pagination: {
    display: 'flex',
    flexDirection: 'row'
  }
});
const TablePaginationActions = ({ count, page, rowsPerPage, onChangePage }) => {
  const classes = useStyles2();

  const handleFirstPageButtonClick = event => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = event => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = event => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = event => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const isDisabledNext = page >= Math.ceil(count / rowsPerPage) - 1;
  const isDisabledPrevious = page === 0;

  return (
    <div className={classes.pagination}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={isDisabledPrevious}
        aria-label="first page"
      >
        <FirstPageIcon />
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={isDisabledPrevious}
        aria-label="previous page"
      >
        <KeyboardArrowLeft />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={isDisabledNext} aria-label="next page">
        <KeyboardArrowRight />
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={isDisabledNext}
        aria-label="last page"
      >
        <LastPageIcon />
      </IconButton>
    </div>
  );
};
TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired
};

const UserEventsPagination = ({
  data,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage
}) => {
  const csvData = data.map(item => {
    const retVal = { ...item, ...item.properties };
    delete retVal.properties;

    return retVal;
  });
  const classes = useStyles1();

  const pageOptions = [25, 50, 100, { label: 'All', value: -1 }];

  const selectProps = {
    inputProps: { 'aria-label': 'rows per page' },
    native: true
  };
  const count = data.length;
  return (
    <TableFooter>
      <TableRow>
        <TablePagination
          rowsPerPageOptions={pageOptions}
          colSpan={3}
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          SelectProps={selectProps}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
        <TableCell>
          <ButtonSubmit>
            <CSVLink className={classes.btn} data={csvData}>
              Download
            </CSVLink>
          </ButtonSubmit>
        </TableCell>
      </TableRow>
    </TableFooter>
  );
};
UserEventsPagination.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      user_id: PropTypes.number,
      device_ip_address: PropTypes.string,
      device_client: PropTypes.string,
      device_user_agent: PropTypes.string,
      event: PropTypes.string,
      result: PropTypes.string,
      result_code: PropTypes.number,
      properties: PropTypes.shape({}),
      session: PropTypes.string,
      created_at: PropTypes.string,
      updated_at: PropTypes.string,
      fingerprint: PropTypes.string
    })
  ).isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRowsPerPage: PropTypes.func.isRequired
};
export default UserEventsPagination;
