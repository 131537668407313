import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Collapse, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import Link from '~components/Link';
import DeleteIcon from '@material-ui/icons/DeleteOutline';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '155px'
  },
  content: {
    padding: 0
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const ExpandMore = styled(props => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const AccountDeletionRequests = ({ requests, isError, isLoading, error }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  if (isLoading) {
    return (
      <Card className={classes.root}>
        <CardHeader title="Account Deletion Requests" />
        <CardContent>Loading data...</CardContent>
      </Card>
    );
  }
  if (isError) {
    return (
      <Card className={classes.root}>
        <CardHeader title="Account Deletion Requests" />
        <CardContent>Error loading data! Error was: {JSON.stringify(error)}</CardContent>
      </Card>
    );
  }

  if (requests.length < 1) {
    return (
      <Card className={classes.root}>
        <CardHeader title="Account Deletion Requests" />
        <CardContent>Nobody requested an account deletion.</CardContent>
      </Card>
    );
  }

  return (
    <Card className={classes.root}>
      <CardHeader
        title="Account Deletion Requests"
        action={
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      {!isExpanded && <CardContent>Press the arrow above to expand the list of users</CardContent>}
      <Collapse in={isExpanded}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <Table>
              <TableBody align="left">
                {requests.map(request => (
                  <TableRow key={request.user_id}>
                    <TableCell>
                      <Link to={`/admin/users-edit/${request.user_id}`}>
                        <span className={classes.statusContainer}>
                          <DeleteIcon className={classes.icon} />
                          {request.username}
                        </span>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </CardContent>
      </Collapse>
    </Card>
  );
};

AccountDeletionRequests.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isError: PropTypes.bool.isRequired,
  error: PropTypes.shape({}),
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      user_id: PropTypes.number,
      username: PropTypes.string
    })
  ).isRequired
};

AccountDeletionRequests.defaultProps = {
  error: null
};

export default AccountDeletionRequests;
