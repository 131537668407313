import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useForm } from 'react-hook-form';

import useApi, { ADMIN_CREATE_USER } from '~hooks/useApi';
import { pushDataLayerEvent, makeSubscriptionPlanName } from '~utils/data-layer';

import UserForm from '~components/admin/Users/UserForm';

const AdminUserAdd = () => {
  const { register, handleSubmit } = useForm();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;
  const [verified, setVerified] = useState(false);
  const [accessLevel, setAccessLevel] = useState(0);
  const [planPeriod, setPlanPeriod] = useState();
  const [bmAccess, setBMAccess] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [admin, setAdmin] = useState(false);

  const onSubmit = userData => {
    const newData = { ...userData };
    if (newData.subscription_expires_at === '') {
      delete newData.subscription_expires_at;
    }
    if (newData.subscription_plan_id === '') {
      delete newData.subscription_plan_id;
    }
    if (newData.access_level === '') {
      newData.access_level = null;
    }
    const params = ADMIN_CREATE_USER({ user: newData });
    makeRequest(params);
  };

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }
    if (data) {
      pushDataLayerEvent('sign_up', {
        signUpMethod: 'admin_created',
        userId: data.id,
        userSubscriptionPlanName: makeSubscriptionPlanName(data),
        userSubscriptionPlanPeriod: data.subscription_plan_period
      });

      navigate('/admin/users');
    }
  }, [data, isError, isLoading]);

  const handleFieldChange = () => setVerified(!verified);

  const handleAccessLevelChange = event => {
    setAccessLevel(event.target.value);
  };

  const handlePlanPeriodChange = event => {
    setPlanPeriod(event.target.value);
  };

  const handleBMAccessChange = () => setBMAccess(!bmAccess);

  const handleNewsletterChange = () => setNewsletter(!newsletter);

  const handleAdminChange = () => setAdmin(!admin);

  return (
    <UserForm
      handlers={{
        onSubmit,
        handleSubmit,
        handleFieldChange,
        handleNewsletterChange,
        handleAdminChange,
        handleBMAccessChange,
        handleAccessLevelChange,
        handlePlanPeriodChange
      }}
      register={register}
      isLoading={isLoading}
      isError={isError}
      error={error}
      isAddUser
      verified={verified}
      accessLevel={accessLevel}
      planPeriod={planPeriod}
      bmAccess={bmAccess}
      newsletter={newsletter}
      admin={admin}
    />
  );
};

export default AdminUserAdd;
