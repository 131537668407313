export const initState = (startDate, endDate) => ({
  startDate,
  endDate,
  filterBy: [],
  responseData: []
});

export const reducer = (state, action) => {
  switch (action.type) {
    case 'startDate':
      return {
        ...state,
        startDate: action.payload
      };
    case 'endDate':
      return {
        ...state,
        endDate: action.payload
      };
    case 'filterBy-add':
      return {
        ...state,
        filterBy: [...state.filterBy, action.payload]
      };
    case 'filterBy-remove':
      return {
        ...state,
        filterBy: state.filterBy.filter(arrItem => arrItem !== action.payload)
      };
    case 'responseData':
      return {
        ...state,
        responseData: action.payload
      };
    case 'responseData-filter':
      return {
        ...state,
        responseData: action.payload.filter(arrItem => state.filterBy.includes(arrItem.event))
      };
    default:
      return state;
  }
};
