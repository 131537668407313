import React, { useEffect, useReducer } from 'react';

import useAnalyticEventsForUser from '~hooks/useAnalyticEventsForUser/useAnalyticEventsForUser';
import UserEventsTopBar from './UserEventsTopBar';
import UserEventsTable from './Table/UserEventsTable';
import { getUniqueValues, defaultStartDate, defaultEndDate } from './Utils/utils';
import { initState, reducer } from './Reducer/userEventsReducer';

const UserEventTable = () => {
  const [state, dispatch] = useReducer(reducer, initState(defaultStartDate, defaultEndDate));
  const { startDate, endDate, filterBy, responseData } = state;
  const { data, isLoading, isError, error, requestData } = useAnalyticEventsForUser(dispatch);

  const handleStartDate = e => dispatch({ type: 'startDate', payload: e.target.value });
  const handleEndDate = e => dispatch({ type: 'endDate', payload: e.target.value });
  const onSelectEvent = item => event => {
    if (event.target.checked) {
      dispatch({ type: 'filterBy-add', payload: item });
    } else {
      dispatch({ type: 'filterBy-remove', payload: item });
    }
  };

  useEffect(() => {
    if (!data) {
      return;
    }

    if (filterBy.length) {
      dispatch({ type: 'responseData-filter', payload: data });
    } else {
      dispatch({ type: 'responseData', payload: data });
    }
  }, [filterBy, data]);

  const dates = {
    startDate,
    endDate,
    defaultEndDate
  };

  const handlers = {
    handleStartDate,
    handleEndDate,
    requestData
  };

  const eventList = getUniqueValues(data?.map(item => item.event));

  return (
    <>
      <UserEventsTopBar
        dates={dates}
        handlers={handlers}
        eventList={eventList}
        onSelectEvent={onSelectEvent}
      />
      <UserEventsTable isLoading={isLoading} isError={isError} error={error} data={responseData} />
    </>
  );
};

export default UserEventTable;
