import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { navigate } from 'gatsby';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import UserEventTable from '~components/admin/Users/UserEvents/UserEvents';

import useApi, {
  ADMIN_GET_USER_LIST_ONE,
  ADMIN_UNLOCK_USER,
  ADMIN_AUTO_SIGN_USER,
  ADMIN_DELETE_USER,
  ADMIN_UPDATE_USER,
  ADMIN_VERIFY_USER_APPSTORE_RECEIPT,
  ADMIN_FIND_USER_ACCOUNT_DELETION_REQUEST,
  ADMIN_FULFILL_USER_ACCOUNT_DELETION_REQUEST
} from '~hooks/useApi';

import { useUserContext } from '~context/UserContext/UserSessionContext';

import * as ACTIONS from '~context/UserContext/actions/actions';

import request, { setAuthHeader } from '~utils/request';

import UserForm from '~components/admin/Users/UserForm';
import LoadingSpinner from '~src/components/LoadingSpinner';
import useConversionFramework from '~hooks/useConversionFramework/useConversionFramework';

const a11yProps = index => ({
  id: `admin-tab-${index}`,
  'aria-controls': `admin-tab-${index}`
});

const TabPanel = props => {
  const { children, value, index, ...rest } = props;

  if (value !== index) {
    return null;
  }

  return (
    <div role="tabpanel" id={`admin-tab-${index}`} {...rest}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired
};

const AdminUserEdit = ({ id }) => {
  const { register, handleSubmit } = useForm();
  const [{ isLoading }] = useApi();
  const { dispatch } = useUserContext();
  const [verified, setVerified] = useState(false);
  const [data, setData] = useState();
  const [accessLevel, setAccessLevel] = useState(0);
  const [planPeriod, setPlanPeriod] = useState('month');
  const [bmAccess, setBMAccess] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const [admin, setAdmin] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [openAlertDelete, setAlertDelete] = useState(false);
  const [tab, setTab] = useState(0);
  const { isMarketingSite } = useConversionFramework();

  // ternary logic:
  // `undefined`: "don't know"
  // `true`: "definitely yes"
  // `false`: "definitely no"
  const [isRequestedAccountDeletion, setRequestedAccountDeletion] = useState(undefined);
  // NOTE: only technical errors!
  const [errorGettingAccountDeletionRequest, setErrorGettingAccountDeletionRequest] =
    useState(undefined);

  const handleChange = (_, index) => {
    setTab(index);
  };
  const onSubmit = d => {
    const newData = { ...d };
    /*
     * Data cleanup for Knex at backend.
     * Rules are:
     * 1. If the property does not exist or have a value of `undefined`, it's not changed in the database record.
     * 2. If the property have a value of exactly `null` then it's set to NULL in the database record.
     * 3. Value of `''` (empty string) is written to database as an *EMPTY STRING*, which is *NOT NULL*!
     */
    if (newData.username === '') {
      delete newData.username;
    }
    if (newData.password === '') {
      delete newData.password;
    }
    if (newData.subscription_gateway_stripe_id === '') {
      newData.subscription_gateway_stripe_id = null;
    }
    if (newData.subscription_gateway_paypal_id === '') {
      newData.subscription_gateway_paypal_id = null;
    }
    if (newData.subscription_gateway_appstore_id === '') {
      newData.subscription_gateway_appstore_id = null;
    }
    if (newData.subscription_gateway_gplay_id === '') {
      newData.subscription_gateway_gplay_id = null;
    }
    if (newData.subscription_expires_at === '') {
      newData.subscription_expires_at = null;
    }
    if (newData.subscription_plan_id === '') {
      newData.subscription_plan_id = null;
    }
    if (newData.access_level === '') {
      newData.access_level = null;
    }
    if (newData.subscription_plan_period === '') {
      newData.subscription_plan_period = null;
    }
    request(ADMIN_UPDATE_USER({ user: newData, id }))
      .then(response => {
        if (response.status === 200) {
          setOpenSuccess(true);
        }
      })
      .catch(error => {
        setIsError(true);
        const errMessage = error.response.data.message || error.message;
        setErrorMessage(errMessage);
        console.error(errMessage); // eslint-disable-line
      });
  };

  useEffect(() => {
    request(ADMIN_GET_USER_LIST_ONE({ id }))
      .then(response => {
        setVerified(response.data.is_verified);
        setAccessLevel(response.data.access_level);
        setPlanPeriod(response.data.subscription_plan_period);
        setBMAccess(response.data.has_bar_mitzvah_access);
        setNewsletter(response.data.is_newsletter_active || false);
        setAdmin(response.data.is_admin || false);
        setData(response.data);
      })
      .catch(error => {
        setIsError(true);
        setErrorMessage(error.message);
        console.error(error); // eslint-disable-line
      });
  }, [id]);

  useEffect(() => {
    request(ADMIN_FIND_USER_ACCOUNT_DELETION_REQUEST({ userId: id }))
      .then(response => {
        const isFoundDeletionRequest =
          !!response && !!response.data && !!response.data.user_id && response.data.user_id === +id;
        setRequestedAccountDeletion(isFoundDeletionRequest);
        setErrorGettingAccountDeletionRequest(undefined);
      })
      .catch(error => {
        setErrorGettingAccountDeletionRequest(error.message);
        console.error(error); // eslint-disable-line
      });
  }, [id]);

  const handleFieldChange = () => {
    setVerified(!verified);
  };

  const handleVerifyAppStoreReceipt = () => {
    request(ADMIN_VERIFY_USER_APPSTORE_RECEIPT({ id }))
      .then(response => {
        if (response.data.updatedUser) {
          setData(response.data.updatedUser);
        }
        setOpenSuccess(true);
      })
      .catch(error => {
        setIsError(true);
        setErrorMessage(error.message);
        console.error(error); // eslint-disable-line
      });
  };

  const handleUnlockUser = () => {
    request(ADMIN_UNLOCK_USER({ id }))
      .then(response => {
        setData(response.data);
        setOpenSuccess(true);
      })
      .catch(error => {
        setIsError(true);
        setErrorMessage(error.message);
        console.error(error); // eslint-disable-line
      });
  };

  const handleLoginUser = () => {
    request(ADMIN_AUTO_SIGN_USER({ id }))
      .then(response => {
        setAuthHeader(response.data.token, isMarketingSite);
        dispatch({ type: ACTIONS.USER_READY, payload: response.data.user });
        navigate('/');
      })
      .catch(error => {
        setIsError(true);
        setErrorMessage(error.message);
        console.error(error); // eslint-disable-line
      });
  };

  const handleAccessLevelChange = event => {
    setAccessLevel(event.target.value);
  };

  const handlePlanPeriodChange = event => {
    setPlanPeriod(event.target.value);
  };

  const handleBMAccessChange = () => {
    setBMAccess(!bmAccess);
  };

  const handleNewsletterChange = () => setNewsletter(!newsletter);

  const handleAdminChange = () => setAdmin(!admin);

  const handleDeleteUser = () => {
    request(ADMIN_DELETE_USER({ id }))
      // eslint-disable-next-line
      .then(response => {
        navigate('/admin/users');
      })
      .catch(error => {
        setIsError(true);
        setErrorMessage(error.message);
        console.error(error); // eslint-disable-line
      });
  };

  const handleFulfillAccountDeletionRequest = () => {
    request(ADMIN_FULFILL_USER_ACCOUNT_DELETION_REQUEST({ userId: id }))
      // eslint-disable-next-line
      .then(response => {
        // eslint-disable-next-line no-console
        navigate('/admin/users');
      })
      .catch(error => {
        setIsError(true);
        setErrorMessage(error.message);
        console.error(error); // eslint-disable-line
      });
  };

  const handleSuccessClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
  };

  const handleAlertDeleteOpen = () => {
    setAlertDelete(true);
  };

  const handleAlertDeleteClose = e => {
    setAlertDelete(false);
    if (e === 'delete') {
      handleDeleteUser();
    }
  };

  return (
    <>
      <AppBar position="static">
        <Tabs value={tab} onChange={handleChange} aria-label="admin-tab">
          <Tab label="Edit" {...a11yProps(0)} />
          <Tab label="Events" {...a11yProps(1)} />
        </Tabs>
      </AppBar>

      <TabPanel value={tab} index={0}>
        {data ? (
          <UserForm
            id={id}
            data={data}
            handlers={{
              onSubmit,
              handleSubmit,
              handleAlertDeleteOpen,
              handleSuccessClose,
              handleAlertDeleteClose,
              handleFieldChange,
              handleUnlockUser,
              handleNewsletterChange,
              handleAdminChange,
              handleBMAccessChange,
              handleAccessLevelChange,
              handlePlanPeriodChange,
              handleLoginUser,
              handleVerifyAppStoreReceipt,
              handleFulfillAccountDeletionRequest
            }}
            openAlertDelete={openAlertDelete}
            register={register}
            isLoading={isLoading}
            isError={isError}
            error={errorMessage}
            verified={verified}
            accessLevel={accessLevel}
            planPeriod={planPeriod}
            openSuccess={openSuccess}
            bmAccess={bmAccess}
            newsletter={newsletter}
            admin={admin}
            isRequestedAccountDeletion={isRequestedAccountDeletion}
            errorGettingAccountDeletionRequest={errorGettingAccountDeletionRequest}
          />
        ) : (
          <LoadingSpinner />
        )}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <UserEventTable />
      </TabPanel>
    </>
  );
};

AdminUserEdit.propTypes = {
  id: PropTypes.string.isRequired
};

export default AdminUserEdit;
