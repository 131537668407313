import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import useApi, { ADMIN_CREATE_USERS_BULK } from '~hooks/useApi';
import { pushDataLayerEvent } from '~src/utils/data-layer';
import UserBulkAddForm from '~src/components/admin/Users/UserBulkAddForm';
import LoadingSpinner from '~src/components/LoadingSpinner';

const ResultsTable = ({ results }) => (
  <table>
    <thead>
      <tr>
        <th>Username</th>
        <th>Result</th>
      </tr>
    </thead>
    <tbody>
      {results && results.length ? (
        results.map(({ username, result }) => (
          <tr style={{ backgroundColor: result === 'OK' ? 'lime' : 'red' }}>
            <td>{username}</td>
            <td>{result}</td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan={2}>No results</td>
        </tr>
      )}
    </tbody>
  </table>
);

ResultsTable.propTypes = {
  results: PropTypes.arrayOf(
    PropTypes.shape({ username: PropTypes.string, result: PropTypes.string })
  )
};
ResultsTable.defaultProps = {
  results: []
};

const AdminUserBulkAdd = () => {
  const { register, handleSubmit } = useForm();
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  const onSubmit = userData => {
    const { users } = userData;
    const params = ADMIN_CREATE_USERS_BULK({ users });
    makeRequest(params);
  };

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }
    if (data && data.results && data.results && data.results.length) {
      data.results.forEach(({ result, id }) => {
        if (result !== 'OK' || !id) {
          return;
        }
        pushDataLayerEvent('sign_up', {
          signUpMethod: 'admin_created',
          userId: id
        });
      });
    }
  }, [data, isError, isLoading]);

  return (
    <>
      <h1>Bulk Create Users</h1>
      <p>
        This text field allows you to create user accounts in bulk. Enter the emails of the users
        you wish to register in the website.
      </p>
      <p>
        Please note the extremely important details:
        <ol>
          <li>Passwords would be generated automatically</li>
          <li>
            Users and customer support will receive the notification emails as with usual sign up.
          </li>
          <li>Users will be registered in MailChimp!</li>
          <li>If MailChimp rejects the email, user will not be registered.</li>
        </ol>
        <strong>
          500 email addresses are being processed in approximately a minute. For technical reasons,
          do not input more than that.
        </strong>
      </p>
      <UserBulkAddForm
        handlers={{
          onSubmit,
          handleSubmit
        }}
        register={register}
        isLoading={isLoading}
        isError={isError}
        error={error}
      />
      {isLoading ? (
        <>
          <LoadingSpinner />
          Waiting for the processing to end...
        </>
      ) : (
        <ResultsTable results={data && data.results} />
      )}
    </>
  );
};

export default AdminUserBulkAdd;
