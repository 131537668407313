import React from 'react';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';

import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '155px'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  avatar: {
    backgroundColor: '#76c79d',
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    // color: theme.palette.success.dark
  },
  differenceValue: {
    // color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const TotalUsers = props => {
  const { users, ...rest } = props;

  const classes = useStyles();

  const isUsersPositive = Math.sign(parseInt(users.change, 10)) === 1;

  function roundIt(value, decimals) {
    return Number(`${Math.round(`${value}e${decimals}`)}e-${decimals}`);
  }

  return (
    <Card {...rest} className={classes.root}>
      <CardContent>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography
              className={classes.title}
              color="textSecondary"
              gutterBottom
              variant="body2"
            >
              TOTAL USERS
            </Typography>
            <Typography variant="h3">{users.total}</Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <PeopleIcon className={classes.icon} />
            </Avatar>
          </Grid>
        </Grid>
        <div className={classes.difference}>
          {isUsersPositive ? (
            <ArrowUpwardIcon />
          ) : (
            <ArrowDownwardIcon className={classes.differenceIcon} />
          )}

          <Typography className={classes.differenceValue} variant="body2">
            {roundIt(users.percent, 2)}%&nbsp;&nbsp;
          </Typography>
          <Typography className={classes.caption} variant="caption">
            Last 30 Days
          </Typography>
        </div>
      </CardContent>
    </Card>
  );
};

TotalUsers.propTypes = {
  users: PropTypes.shape({
    total: PropTypes.string,
    percent: PropTypes.number,
    change: PropTypes.string
  }).isRequired
};

export default TotalUsers;
