import { useEffect } from 'react';
import useApi, { ANALYTICS_GET_USER_EVENTS } from '~src/hooks/useApi';

const useAnalyticEventsForUser = setOuterState => {
  const [state, makeEventRequest] = useApi();
  const { data, isLoading, isError, error } = state;

  useEffect(() => {
    if (isLoading || isError) {
      return;
    }

    if (data) {
      setOuterState({ type: 'responseData', payload: data });
    }
  }, [data, isError, isLoading, setOuterState]);

  const requestData = (from, to, userId) => () => {
    const params = ANALYTICS_GET_USER_EVENTS({ from, to, userId });
    makeEventRequest(params);
  };

  return { data, isLoading, isError, error, requestData };
};

export default useAnalyticEventsForUser;
