/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import MaterialTable from 'material-table';

import useApi, { ADMIN_VIDEO_REPORT } from '~hooks/useApi';

import LoadingSpinner from '~components/LoadingSpinner';
import MessageBox from '~components/forms/MessageBox';
import Link from '~components/Link';
import tableIcons from '~components/admin/tableIcons';
import linkResolver from '~prismic/utils/link-resolver';
import { defaultStartDate, defaultEndDate } from '~components/admin/Users/UserEvents/Utils/utils';

const validationErrorMessage =
  'You need to put a specific start and end date for this database request.';

const useStyles = makeStyles({
  wrapper: {
    alignItems: 'flex-start'
  },
  tabsRoot: {
    marginBottom: '15px',
    maxWidth: '100%'
  },
  root: {
    padding: '0px !important',
    marginRight: '40px !important',
    minWidth: 'unset'
  },
  indicator: {
    height: '3px'
  }
});

const AdminVideoReport = () => {
  const classes = useStyles();

  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [isValidationError, setIsValidationError] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const onSubmit = () => {
    setIsValidationError(false);

    const params = ADMIN_VIDEO_REPORT({ startDate, endDate });
    makeRequest(params);
  };

  const handleStartDateChange = event => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = event => {
    setEndDate(event.target.value);
  };

  const handleTabSwitch = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const videos =
    data && data.videos
      ? Object.entries(data.videos).map(([prismic_id, video]) => ({ ...video, prismic_id }))
      : [];
  const playlists =
    data && data.playlists
      ? Object.entries(data.playlists).map(([prismic_id, playlist]) => ({
          ...playlist,
          prismic_id
        }))
      : [];

  let tableTitle = 'Playlists';
  let tableData = playlists;
  let tableTitleRenderer = row => (
    <Link to={linkResolver({ uid: row.uid, type: 'playlist' })}>{row.title}</Link>
  );
  let tableIdRenderer = row => (
    <Link
      to={`https://aleph-beta.prismic.io/documents~k=playlist&b=working&c=published&l=en-us/${row.prismic_id}/`}
    >
      Open in Prismic&nbsp;&gt;
    </Link>
  );
  if (currentTab === 1) {
    tableTitle = 'Videos';
    tableData = videos;
    tableTitleRenderer = row => (
      <Link to={linkResolver({ uid: row.uid, type: 'video', playlist_uid: row.playlist_uid })}>
        {row.title}
      </Link>
    );
    tableIdRenderer = row => (
      <Link
        to={`https://aleph-beta.prismic.io/documents~k=video&b=working&c=published&l=en-us/${row.prismic_id}/`}
      >
        Open in Prismic&nbsp;&gt;
      </Link>
    );
  }
  return (
    <Box p={4}>
      <Typography variant="h4">Admin Database Stats</Typography>
      <Box boxShadow={2} p={2} mt={2}>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Start Date"
              type="date"
              onChange={handleStartDateChange}
              defaultValue={startDate}
              name="startDate"
              InputLabelProps={{
                shrink: true
              }}
              style={{ paddingRight: '20px' }}
            />
            <TextField
              label="End Date"
              type="date"
              name="endDate"
              onChange={handleEndDateChange}
              defaultValue={endDate}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Button
              variant="outlined"
              type="submit"
              color="primary"
              fullWidth
              disabled={isLoading}
              onClick={onSubmit}
            >
              <span style={{ width: '100%' }}>Report</span>
            </Button>
          </Grid>

          <Grid item xs={12}>
            {(isError || isValidationError) && (
              <MessageBox text={error || validationErrorMessage} isError />
            )}
          </Grid>
        </Grid>

        {isLoading && <LoadingSpinner textAlign="center" />}
      </Box>

      <Tabs
        value={currentTab}
        onChange={handleTabSwitch}
        variant="scrollable"
        indicatorColor="primary"
        classes={{ indicator: classes.indicator, root: classes.tabsRoot }}
      >
        <Tab label="Playlists" className={classes.root} classes={{ wrapper: classes.wrapper }} />
        <Tab label="Videos" className={classes.root} classes={{ wrapper: classes.wrapper }} />
      </Tabs>

      <MaterialTable
        isLoading={isLoading}
        icons={tableIcons}
        title={tableTitle}
        columns={[
          { title: 'Title', field: 'title', render: tableTitleRenderer },
          {
            title: 'Total Minutes Viewed',
            field: 'minutes_total',
            defaultSort: 'desc',
            type: 'numeric'
          },
          {
            title: 'Times Watched Completely',
            field: 'times_watched',
            type: 'numeric'
          },
          { title: 'Users', field: 'users', type: 'numeric' },
          {
            title: 'CMS URL',
            field: 'prismic_id',
            render: tableIdRenderer
          }
        ]}
        data={tableData}
        options={{
          debounceInterval: 750,
          pageSize: 100,
          pageSizeOptions: [100, 1000]
        }}
      />
    </Box>
  );
};

export default AdminVideoReport;
