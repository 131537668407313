import React, { useEffect } from 'react';
import { get } from 'lodash';
import { Link as GatsbyLink } from 'gatsby';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import useApi, { ADMIN_GET_DASHBOARD } from '~hooks/useApi';

import Subscriptions from '~components/admin/Dashboard/components/Subscriptions';
import TotalUsers from '~components/admin/Dashboard/components/TotalUsers';
// import TasksProgress from '~components/admin/Dashboard/components/TasksProgress';
import TotalFailed from '~components/admin/Dashboard/components/TotalFailed';
import FailedUsers from '~components/admin/Dashboard/components/FailedUsers';
import ViewTimeBreakdown from '~components/admin/Dashboard/components/ViewTimeBreakdown';

import LoadingSpinner from '~src/components/LoadingSpinner';
import AccountDeletionRequests from '~containers/admin/dashboard/AccountDeletionRequests';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const AdminIndex = () => {
  const classes = useStyles();
  const [{ isLoading, isError, data }, makeRequest] = useApi();

  const useMountEffect = f => useEffect(f, []);

  useMountEffect(() => {
    if (isLoading || isError) {
      return;
    }
    const adminDashboardParams = ADMIN_GET_DASHBOARD();
    makeRequest(adminDashboardParams);
  });

  const failedCharges = get(data, 'failedCharges', {});
  const failedChargesList = get(data, 'failedCharges.list', []);
  // const freeSubscriptions = get(data, 'freeSubscriptions', {});
  // const paidSubscriptions = get(data, 'paidSubscriptions', {});
  const subscriptions = get(data, 'subscriptions', {});
  const users = get(data, 'users', {});

  const viewTimeBreakdown = get(data, 'viewTimeBreakdown', {});

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {isLoading && (
          <Grid item xs={12} sm={12} lg={12} xl={12}>
            Please wait, dashboard data is loading...
            <LoadingSpinner />
          </Grid>
        )}
        <Grid item xs={12} sm={6} lg={3} xl={3}>
          <TotalUsers users={users} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} xl={3}>
          <Subscriptions subscriptions={subscriptions} />
        </Grid>
        {/* <Grid item xs={12} sm={6} lg={3} xl={3}>
          <TasksProgress />
        </Grid> */}
        <Grid item xs={12} sm={6} lg={3} xl={3}>
          <TotalFailed failedCharges={failedCharges} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} xl={3}>
          <FailedUsers failedChargesList={failedChargesList} />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} xl={3}>
          <AccountDeletionRequests />
        </Grid>
        <Grid item xs={12} sm={6} lg={3} xl={3}>
          <Button
            component={GatsbyLink}
            to="/admin/users-check-premium"
            variant="contained"
            color="primary"
          >
            Facebook Premium Group Check
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} lg={6} xl={6}>
          <ViewTimeBreakdown viewTimeBreakdown={viewTimeBreakdown} />
        </Grid>
      </Grid>
    </div>
  );
};

export default AdminIndex;
