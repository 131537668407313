import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';

import LockIcon from '@material-ui/icons/Lock';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Collapse, IconButton } from '@material-ui/core';
import { styled } from '@material-ui/styles';

import Link from '~components/Link';

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '155px'
  },
  content: {
    padding: 0
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  icon: {
    marginRight: theme.spacing(1)
  }
}));

const ExpandMore = styled(props => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}));

const FailedUsers = ({ failedChargesList, ...rest }) => {
  const classes = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Card {...rest} className={classes.root}>
      <CardHeader
        title="Users with Failed Charges"
        action={
          <ExpandMore
            expand={isExpanded}
            onClick={() => setIsExpanded(!isExpanded)}
            aria-expanded={isExpanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        }
      />
      {!isExpanded && <CardContent>Press the arrow above to expand the list of users</CardContent>}
      <Collapse in={isExpanded}>
        <CardContent className={classes.content}>
          <PerfectScrollbar>
            <Table>
              <TableBody align="left">
                {failedChargesList.map(user => (
                  <TableRow key={user.id}>
                    <TableCell>
                      <Link to={`/admin/users-edit/${user.id}`}>
                        <span className={classes.statusContainer}>
                          <LockIcon className={classes.icon} />
                          {user.username}
                        </span>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}

                {failedChargesList.length === 0 && (
                  <TableRow hover>
                    <TableCell>
                      <div className={classes.statusContainer}>No Failed Charges</div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </PerfectScrollbar>
        </CardContent>
      </Collapse>
    </Card>
  );
};

FailedUsers.propTypes = {
  failedChargesList: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default FailedUsers;
