import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CardHeader, CardContent, Card } from '@material-ui/core';
import { localeDate } from '~utils/dates';

const useStyles = makeStyles(() => ({
  root: {
    minHeight: '155px'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700
  },
  cellWithNumbers: {
    textAlign: 'right'
  }
}));

function formatDateRange(dateRange) {
  const [startDateTimeString, endDtateTimeString] = dateRange.split('|');
  const formattedStartDate = localeDate(startDateTimeString, { month: 'short', day: 'numeric' });
  const formattedEndDate = localeDate(endDtateTimeString, { month: 'short', day: 'numeric' });

  return `${formattedStartDate}-${formattedEndDate}`;
}

const ViewTimeBreakdown = ({ viewTimeBreakdown, ...rest }) => {
  const classes = useStyles();
  const dateRanges = Object.keys(viewTimeBreakdown);
  dateRanges.sort((left, right) => left.localeCompare(right));
  return (
    <Card {...rest} className={classes.root}>
      <CardHeader title="Engagement Time Breakdown" />
      <CardContent>
        <table border={1} cellPadding={6} cellSpacing={0}>
          <thead>
            <tr>
              <th rowSpan={2} aria-label="Content type" />
              <th colSpan={dateRanges.length} title="Week starts at Sunday">
                Hours per Week
              </th>
            </tr>
            <tr>
              {dateRanges.map(dateRange => (
                <th key={dateRange} title="Both dates are included">
                  {formatDateRange(dateRange)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Android Videos</td>
              {dateRanges.map(dateRange => (
                <td key={dateRange} className={classes.cellWithNumbers}>
                  {viewTimeBreakdown[dateRange].androidVideoHours}
                </td>
              ))}
            </tr>
            <tr>
              <td>Android Audios</td>
              {dateRanges.map(dateRange => (
                <td key={dateRange} className={classes.cellWithNumbers}>
                  {viewTimeBreakdown[dateRange].androidAudioHours}
                </td>
              ))}
            </tr>
            <tr>
              <td>iOS Videos</td>
              {dateRanges.map(dateRange => (
                <td key={dateRange} className={classes.cellWithNumbers}>
                  {viewTimeBreakdown[dateRange].iOsVideoHours}
                </td>
              ))}
            </tr>
            <tr>
              <td>iOS Audios</td>
              {dateRanges.map(dateRange => (
                <td key={dateRange} className={classes.cellWithNumbers}>
                  {viewTimeBreakdown[dateRange].iOsAudioHours}
                </td>
              ))}
            </tr>
            <tr>
              <td>Web Videos</td>
              {dateRanges.map(dateRange => (
                <td key={dateRange} className={classes.cellWithNumbers}>
                  {viewTimeBreakdown[dateRange].webVideoHours}
                </td>
              ))}
            </tr>
            <tr>
              <td>Mobile Web Videos</td>
              {dateRanges.map(dateRange => (
                <td key={dateRange} className={classes.cellWithNumbers}>
                  {viewTimeBreakdown[dateRange].webMobileVideoHours}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </CardContent>
    </Card>
  );
};

ViewTimeBreakdown.propTypes = {
  viewTimeBreakdown: PropTypes.shape({}).isRequired
};

export default ViewTimeBreakdown;
