import React from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/Visibility';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  }
}));

const PopupsFlow = ({ title, popups, showPopup, ...rest }) => {
  const classes = useStyles();

  return (
    <Card {...rest} className={classes.root}>
      <CardHeader title={title} />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {popups &&
            popups.map((popup, index) => (
              <React.Fragment key={popup.title}>
                {index !== 0 && (
                  <ListItem>
                    <ArrowDownwardIcon />
                  </ListItem>
                )}
                <ListItem>
                  <ListItemText primary={popup.title} />
                  <IconButton edge="end" size="small" onClick={() => showPopup(popup.id)}>
                    <MoreVertIcon />
                  </IconButton>
                </ListItem>
              </React.Fragment>
            ))}
        </List>
      </CardContent>
    </Card>
  );
};

PopupsFlow.propTypes = {
  showPopup: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  popups: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired
    })
  ).isRequired
};

export default PopupsFlow;
