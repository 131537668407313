import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { startCase, camelCase } from 'lodash';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import useApi, { ADMIN_STATS } from '~hooks/useApi';

import LoadingSpinner from '~components/LoadingSpinner';
import MessageBox from '~components/forms/MessageBox';

import { stringFromUserAccessLevel } from '~utils/playlist-helpers';
import { defaultStartDate, defaultEndDate } from '~components/admin/Users/UserEvents/Utils/utils';

const DB_QUERIES_DATA = [
  {
    id: 1,
    title: 'Average watched minutes per user'
  },
  {
    id: 2,
    title: 'Total count of captured emails'
  },
  {
    id: 3,
    title: 'Total count of subscription events'
  },
  {
    id: 4,
    title: 'number of Patrons Circle members'
  },
  {
    id: 6,
    title: 'average watched minutes per subscriber'
  },
  {
    id: 8,
    title: 'Non-PC users who opened /patrons-circle page'
  },
  {
    id: 9,
    title: 'Total number of minutes watched by everyone'
  },
  {
    id: 10,
    title: 'Email capture sources'
  },
  {
    id: 11,
    title: 'Conversion rate'
  },
  {
    id: 12,
    title: 'Pre-cancellation offers performance'
  },
  {
    id: 13,
    title: 'Still subscribed after accepting anti-churn offer'
  },
  {
    id: 14,
    title: 'Subscription Cancellation Feedback '
  },
  {
    id: 15,
    title: 'Subscription Capture Sources'
  },
  {
    id: 16,
    title: 'View Time Breakdown'
  },
  {
    id: 17,
    title: 'View Most Active Watchers'
  },
  {
    id: 18,
    title: 'View Active Users'
  },
  {
    id: 19,
    title: 'Total Count Of Active Users'
  },
  {
    id: 20,
    title: 'Total Anti Churn Button Clicks'
  }
];

const TableData = styled.td`
  padding: 1rem;
`;

const validationErrorMessage =
  'You need to put a specific start and end date for this database request.';

const LabeledValue = ({ label, value }) => (
  <Box p={3}>
    <Typography variant="h6">
      {startCase(camelCase(label))} : {value}
    </Typography>
  </Box>
);
LabeledValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
};

const ListOfKeyValuePairs = ({ inputObject }) => (
  <>
    {Object.entries(inputObject).map(([label, value]) => (
      <LabeledValue key={`${label}-${value}`} label={label} value={value} />
    ))}
  </>
);
ListOfKeyValuePairs.propTypes = {
  inputObject: PropTypes.shape().isRequired
};

const DataTable = ({ data }) => (
  <table>
    <thead>
      <tr>
        {Object.keys(data[0]).map(label => (
          <th key={label}>{startCase(camelCase(label))}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data.map(row => (
        <tr key={JSON.stringify(row)}>
          {Object.entries(row).map(([label, value]) => (
            <React.Fragment key={`${label}=${value}`}>
              {label === 'subscription_plan_id' /* FIXME: rewrite for access levels! */ ? (
                <TableData>{stringFromUserAccessLevel(value)}</TableData>
              ) : (
                <TableData>{value && value.toString()}</TableData>
              )}
            </React.Fragment>
          ))}
        </tr>
      ))}
    </tbody>
  </table>
);
DataTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const RawDataView = ({ data }) => {
  if (!data.length) {
    return (
      <p>
        <strong>No data</strong>
      </p>
    );
  }
  if (data.length < 2) {
    return <ListOfKeyValuePairs inputObject={data[0]} />;
  }
  return <DataTable data={data} />;
};
RawDataView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

const AdminStatistics = () => {
  const [state, makeRequest] = useApi();
  const { isLoading, isError, data, error } = state;

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(defaultEndDate);
  const [isValidationError, setIsValidationError] = useState(false);

  const onSubmit = queryId => {
    setIsValidationError(false);

    if (queryId !== 4 && (!startDate || !endDate)) {
      setIsValidationError(true);
      return;
    }

    const params = ADMIN_STATS({ queryId, startDate, endDate });
    makeRequest(params);
  };

  const handleStartDateChange = event => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = event => {
    setEndDate(event.target.value);
  };

  const handleClick = event => {
    const queryId = event.target.id;
    onSubmit(queryId);
  };

  return (
    <Box p={4}>
      <Typography variant="h4">Admin Database Stats</Typography>
      <Box boxShadow={2} p={2} mt={2}>
        <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Start Date"
              type="date"
              onChange={handleStartDateChange}
              defaultValue={startDate}
              name="startDate"
              InputLabelProps={{
                shrink: true
              }}
              style={{ paddingRight: '20px' }}
            />
            <TextField
              label="End Date"
              type="date"
              name="endDate"
              onChange={handleEndDateChange}
              defaultValue={endDate}
              InputLabelProps={{
                shrink: true
              }}
            />
          </Grid>
          {DB_QUERIES_DATA &&
            DB_QUERIES_DATA.map(queryData => (
              <Grid key={queryData.id} item xs={12} sm={6} md={4}>
                <Button
                  variant="outlined"
                  type="submit"
                  id={queryData.id}
                  color="primary"
                  fullWidth
                  disabled={isLoading}
                  onClick={handleClick}
                >
                  <span id={queryData.id} style={{ width: '100%' }}>
                    {queryData.title}
                  </span>
                </Button>
              </Grid>
            ))}

          <Grid item xs={12}>
            {(isError || isValidationError) && (
              <MessageBox text={error || validationErrorMessage} isError />
            )}
          </Grid>
        </Grid>

        {isLoading && <LoadingSpinner textAlign="center" />}
      </Box>
      {data && <RawDataView data={data} />}
    </Box>
  );
};

export default AdminStatistics;
