import React from 'react';
import PropTypes from 'prop-types';
import MessageBox from '~components/forms/MessageBox';
import TextAreaInput from '~components/form-inputs/TextAreaInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';

const AdminUserBulkAddForm = ({
  handlers: { handleSubmit, onSubmit },
  register,
  isLoading,
  isError,
  error
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    {isError && <MessageBox text={error} isError />}
    <TextAreaInput name="users" label="Usernames" inputRef={register} />
    <ButtonSubmit disabled={isLoading}>Register all of them</ButtonSubmit>
  </form>
);

AdminUserBulkAddForm.propTypes = {
  handlers: PropTypes.shape({ handleSubmit: PropTypes.func, onSubmit: PropTypes.func }).isRequired,
  register: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.shape({})
};
AdminUserBulkAddForm.defaultProps = {
  isLoading: false,
  isError: false,
  error: undefined
};

export default AdminUserBulkAddForm;
