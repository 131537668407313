import { Button, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  notice: {
    marginTop: theme.spacing(1)
  },
  warning: {
    marginTop: theme.spacing(1),
    color: 'red'
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: '#ffffff',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  }
}));

const DeleteUserButton = ({
  handleAlertDeleteOpen,
  isRequestedAccountDeletion,
  errorGettingAccountDeletionRequest,
  handleFulfillAccountDeletionRequest
}) => {
  const classes = useStyles();

  if (isRequestedAccountDeletion === undefined) {
    return (
      <>
        <Typography className={classes.notice}>Checking for account deletion request...</Typography>
        {errorGettingAccountDeletionRequest && (
          <Typography className={classes.warning}>
            Error checking for account deletion request:{' '}
            {JSON.stringify(errorGettingAccountDeletionRequest)}
          </Typography>
        )}
      </>
    );
  }

  if (isRequestedAccountDeletion === true) {
    return (
      <>
        {errorGettingAccountDeletionRequest && (
          <Typography className={classes.warning}>
            Error checking for account deletion request:{' '}
            {JSON.stringify(errorGettingAccountDeletionRequest)}
          </Typography>
        )}
        <Typography className={classes.notice}>User requested account deletion!</Typography>
        <Typography className={classes.notice}>
          Please note that the button below does not ask questions!
        </Typography>
        <Button className={classes.deleteButton} onClick={handleFulfillAccountDeletionRequest}>
          <DeleteIcon className={classes.buttonIcon} />
          Fulfill Account Deletion Request
        </Button>
      </>
    );
  }
  if (handleAlertDeleteOpen) {
    return (
      <>
        {errorGettingAccountDeletionRequest && (
          <Typography className={classes.warning}>
            Error checking for account deletion request:{' '}
            {JSON.stringify(errorGettingAccountDeletionRequest)}
          </Typography>
        )}
        <Typography className={classes.notice} variant="body2">
          This will remove this customer’s data! Please be aware that once this has been deleted it
          can never brought back!
        </Typography>
        <Button className={classes.deleteButton} onClick={handleAlertDeleteOpen}>
          <DeleteIcon className={classes.buttonIcon} />
          Delete User
        </Button>
      </>
    );
  }

  return (
    errorGettingAccountDeletionRequest && (
      <Typography className={classes.warning}>
        Error checking for account deletion request:{' '}
        {JSON.stringify(errorGettingAccountDeletionRequest)}
      </Typography>
    )
  );
};

DeleteUserButton.propTypes = {
  handleAlertDeleteOpen: PropTypes.func.isRequired,
  isRequestedAccountDeletion: PropTypes.bool,
  errorGettingAccountDeletionRequest: PropTypes.shape({}),
  handleFulfillAccountDeletionRequest: PropTypes.func.isRequired
};

DeleteUserButton.defaultProps = {
  isRequestedAccountDeletion: undefined,
  errorGettingAccountDeletionRequest: undefined
};

export default DeleteUserButton;
