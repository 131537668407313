import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PropTypes from 'prop-types';
import { formatDateYYYYMMDD } from '~utils/dates';
import Grid from '@material-ui/core/Grid';
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Switch,
  Typography,
  Button,
  Select,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormControlLabel,
  TextField,
  IconButton,
  Snackbar,
  SnackbarContent
} from '@material-ui/core';
import PersonIcon from '@material-ui/icons/PersonOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { green } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import classNames from 'classnames';

import TextInput from '~components/form-inputs/TextInput';
import TextAreaInput from '~components/form-inputs/TextAreaInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';
import MessageBox from '~components/forms/MessageBox';
import DeleteUserButton from './DeleteUserButton';

const variantIcon = {
  success: CheckCircleIcon
};

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  content: {
    padding: theme.spacing(2)
  },
  notice: {
    marginTop: theme.spacing(1)
  },
  deleteButton: {
    marginTop: theme.spacing(1),
    color: '#ffffff',
    backgroundColor: theme.palette.error.main,
    '&:hover': {
      backgroundColor: theme.palette.error.dark
    }
  },
  buttonIcon: {
    marginRight: theme.spacing(1)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  success: {
    backgroundColor: green[600]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  },
  card: {
    height: '100%'
  }
}));

const MySnackbarContentWrapper = props => {
  const classes = useStyles();
  const { message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classes.success}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
};

MySnackbarContentWrapper.propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success']).isRequired
};
MySnackbarContentWrapper.defaultProps = {
  onClose: null
};

const checkIfUserIsDoubleSubscriber = user => {
  let subscriptionsCount = 0;
  if (user.subscription_gateway_stripe_id) {
    subscriptionsCount += 1;
  }
  if (user.subscription_gateway_paypal_id) {
    subscriptionsCount += 1;
  }
  if (user.subscription_gateway_gplay_id) {
    subscriptionsCount += 1;
  }
  if (user.appstore_receipt_json && user.appstore_receipt_json.expires_date_ms) {
    if (+user.appstore_receipt_json.expires_date_ms > new Date().valueOf()) {
      subscriptionsCount += 1;
    }
  } else if (user.subscription_gateway_appstore_id) {
    subscriptionsCount += 1;
  }

  return subscriptionsCount > 1;
};

const UserForm = ({
  data,
  handlers,
  register,
  isLoading,
  isError,
  isAddUser,
  error,
  admin,
  bmAccess,
  accessLevel,
  planPeriod,
  verified,
  openAlertDelete,
  newsletter,
  openSuccess,
  isRequestedAccountDeletion,
  errorGettingAccountDeletionRequest
}) => {
  const classes = useStyles();
  const {
    handleSubmit,
    onSubmit,
    handleAdminChange,
    handleBMAccessChange,
    handleAccessLevelChange,
    handlePlanPeriodChange,
    handleFieldChange,
    handleLoginUser,
    handleSuccessClose,
    handleAlertDeleteClose,
    handleAlertDeleteOpen,
    handleUnlockUser,
    handleNewsletterChange,
    handleVerifyAppStoreReceipt,
    handleFulfillAccountDeletionRequest
  } = handlers;

  return (
    <div className={classes.root}>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={openSuccess}
        autoHideDuration={4000}
        onClose={handleSuccessClose}
      >
        <MySnackbarContentWrapper
          onClose={handleSuccessClose}
          variant="success"
          message="User Saved Successfully!"
        />
      </Snackbar>
      <Dialog
        open={openAlertDelete}
        onClose={handleAlertDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete User?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this user?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAlertDeleteClose} color="primary">
            Nevermind
          </Button>
          <Button onClick={() => handleAlertDeleteClose('delete')} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <form onSubmit={handleSubmit(onSubmit)}>
        {isError && <MessageBox text={error} isError />}
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="stretch"
          spacing={4}
        >
          <Grid item md={6} xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Profile" titleTypographyProps={{ variant: 'body1' }} />
              <Divider />
              <CardContent className={classes.content}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="First Name"
                      name="meta_first_name"
                      autoComplete="given-name"
                      defaultValue={data.meta_first_name}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Last Name"
                      name="meta_last_name"
                      autoComplete="family-name"
                      defaultValue={data.meta_last_name}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Phone number"
                      name="meta_phone"
                      type="tel"
                      autoComplete="tel"
                      defaultValue={data.meta_phone}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Address"
                      name="meta_address"
                      autoComplete="street-address"
                      defaultValue={data.meta_address}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={4}>
                    <TextInput
                      label="City"
                      name="meta_city"
                      autoComplete="address-level2"
                      defaultValue={data.meta_city}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextInput
                      label="ZIP"
                      name="meta_zip"
                      autoComplete="postal-code"
                      defaultValue={data.meta_zip}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <TextInput
                      label="Country"
                      name="meta_country"
                      autoComplete="country"
                      defaultValue={data.meta_country}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={6} xs={12}>
            <Card className={classes.card}>
              <CardHeader
                title="Login"
                subheader={data.username && `Username: ${data.username}`}
                titleTypographyProps={{ variant: 'body1' }}
                subheaderTypographyProps={{ variant: 'body2' }}
              />
              <Divider />

              <CardContent className={classes.content}>
                <TextInput
                  label="Username"
                  name="username"
                  type="email"
                  required={!!isAddUser}
                  inputRef={register}
                  disabled={isLoading}
                  margin="dense"
                />
                <TextInput
                  label="Password"
                  name="password"
                  inputRef={register}
                  disabled={isLoading}
                  required={!!isAddUser}
                  inputProps={{
                    minLength: 6
                  }}
                  margin="dense"
                />
                <FormControlLabel
                  control={
                    <Switch
                      color="primary"
                      checked={verified}
                      name="is_verified"
                      value={verified}
                      onChange={handleFieldChange}
                      inputRef={register}
                    />
                  }
                  label="Verified Username"
                />
                <div>
                  {data.has_failed_charges && (
                    <Button onClick={handleUnlockUser}>
                      <PersonIcon className={classes.buttonIcon} />
                      Unlock User
                    </Button>
                  )}
                  {handleLoginUser && (
                    <Button onClick={handleLoginUser}>
                      <PersonIcon className={classes.buttonIcon} />
                      Login as User
                    </Button>
                  )}
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={6} xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Gateways" titleTypographyProps={{ variant: 'body1' }} />
              <Divider />
              <CardContent className={classes.content}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Apple App Store ID"
                      name="subscription_gateway_appstore_id"
                      defaultValue={data.subscription_gateway_appstore_id}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Google Play ID"
                      name="subscription_gateway_gplay_id"
                      defaultValue={data.subscription_gateway_gplay_id}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="Stripe ID"
                      name="subscription_gateway_stripe_id"
                      autoComplete="tel"
                      defaultValue={data.subscription_gateway_stripe_id}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextInput
                      label="PayPal ID"
                      name="subscription_gateway_paypal_id"
                      defaultValue={data.subscription_gateway_paypal_id}
                      inputRef={register}
                      disabled={isLoading}
                      margin="dense"
                    />
                  </Grid>
                </Grid>
                {data.appstore_receipt && (
                  <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item xs={12} md={6}>
                      <TextInput
                        label="AppStore product ID (from receipt)"
                        name="appstore_receipt_product_id"
                        autoComplete="tel"
                        defaultValue={
                          (data.appstore_receipt_json && data.appstore_receipt_json.product_id) ||
                          null
                        }
                        disabled
                        margin="dense"
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextInput
                        label="AppStore Expiration date (from receipt)"
                        name="appstore_receipt_expires_date"
                        defaultValue={
                          (data.appstore_receipt_json && data.appstore_receipt_json.expires_date) ||
                          null
                        }
                        disabled
                        margin="dense"
                      />
                    </Grid>
                  </Grid>
                )}
                {checkIfUserIsDoubleSubscriber(data) && (
                  <Typography color="error">WARNING: This user might be double charged!</Typography>
                )}
                {data.appstore_receipt && (
                  <Button
                    onClick={handleVerifyAppStoreReceipt}
                    title="Will correct the subscription status corresponding to the AppStore receipt IF NO STRIPE OR PAYPAL ID IS FILLED IN. Reload the page after success."
                  >
                    <PersonIcon className={classes.buttonIcon} />
                    Force AppStore receipt verification
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6} xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Plan" titleTypographyProps={{ variant: 'body1' }} />
              <Divider />
              <CardContent className={classes.content}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                      <TextInput
                        label="Plan"
                        name="subscription_plan_id"
                        type="number"
                        defaultValue={data.subscription_plan_id}
                        inputRef={register}
                        margin="none"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="plan_period">Plan Period</InputLabel>
                      <Select
                        native
                        defaultValue={planPeriod}
                        onChange={handlePlanPeriodChange}
                        inputRef={register}
                        input={
                          <OutlinedInput
                            name="subscription_plan_period"
                            id="plan_period"
                            labelWidth={90}
                          />
                        }
                      >
                        <option value="" aria-label="Not selected" />
                        <option value="month">Monthly</option>
                        <option value="year">Yearly</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-end"
                  spacing={2}
                >
                  <Grid item xs={12} md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="date"
                      label="Expiration Date"
                      type="date"
                      name="subscription_expires_at"
                      defaultValue={
                        data.subscription_expires_at &&
                        formatDateYYYYMMDD(data.subscription_expires_at)
                      }
                      className={classes.textField}
                      inputRef={register}
                      InputLabelProps={{
                        shrink: true
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl variant="outlined" className={classes.formControl} fullWidth>
                      <InputLabel htmlFor="access_level" shrink>
                        Access Level
                      </InputLabel>
                      <Select
                        native
                        notched
                        defaultValue={accessLevel}
                        onChange={handleAccessLevelChange}
                        inputRef={register}
                        input={
                          <OutlinedInput name="access_level" id="access_level" labelWidth={95} />
                        }
                      >
                        <option value="">Free User</option>
                        <option value={2}>Premium</option>
                        <option value={3}>Education</option>
                        <option value={4}>Producer</option>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  spacing={2}
                >
                  <Grid item xs={12} md={6} />
                  <Grid item xs={12} md={6}>
                    <FormControlLabel
                      control={
                        <Switch
                          color="primary"
                          checked={bmAccess}
                          name="has_bar_mitzvah_access"
                          value={bmAccess}
                          onChange={handleBMAccessChange}
                          inputRef={register}
                        />
                      }
                      label="Bar &amp;Bat Mitzvah Access"
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={9} xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Admin Notes" titleTypographyProps={{ variant: 'body1' }} />
              <Divider />
              <CardContent className={classes.content}>
                <TextAreaInput
                  name="notes"
                  label="Notes"
                  defaultValue={data.notes}
                  inputRef={register}
                />
              </CardContent>
            </Card>
          </Grid>

          <Grid item md={3} xs={12}>
            <Card className={classes.card}>
              <CardHeader title="Misc" titleTypographyProps={{ variant: 'body1' }} />
              <Divider />
              <CardContent className={classes.content}>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={newsletter}
                        name="is_newsletter_active"
                        value={newsletter}
                        onChange={handleNewsletterChange}
                        inputRef={register}
                      />
                    }
                    label="Newsletter"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={admin}
                        name="is_admin"
                        value={admin}
                        onChange={handleAdminChange}
                        inputRef={register}
                      />
                    }
                    label="Administrator"
                  />
                </div>
                <DeleteUserButton
                  handleAlertDeleteOpen={handleAlertDeleteOpen}
                  isRequestedAccountDeletion={isRequestedAccountDeletion}
                  errorGettingAccountDeletionRequest={errorGettingAccountDeletionRequest}
                  handleFulfillAccountDeletionRequest={handleFulfillAccountDeletionRequest}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <ButtonSubmit fullWidth={false} disabled={isLoading}>
          {isAddUser ? 'Add User' : 'Update User'}
        </ButtonSubmit>
      </form>
    </div>
  );
};

UserForm.propTypes = {
  data: PropTypes.shape({
    username: PropTypes.string,
    subscription_plan_id: PropTypes.number,
    subscription_plan_period: PropTypes.string,
    subscription_gateway_stripe_id: PropTypes.string,
    subscription_gateway_paypal_id: PropTypes.string,
    subscription_gateway_appstore_id: PropTypes.string,
    subscription_gateway_gplay_id: PropTypes.string,
    subscription_expires_at: PropTypes.string,
    meta_first_name: PropTypes.string,
    meta_last_name: PropTypes.string,
    meta_phone: PropTypes.string,
    meta_address: PropTypes.string,
    meta_city: PropTypes.string,
    meta_zip: PropTypes.string,
    meta_country: PropTypes.string,
    is_verified: PropTypes.bool,
    is_newsletter_active: PropTypes.bool,
    is_admin: PropTypes.bool,
    has_failed_charges: PropTypes.bool,
    has_bar_mitzvah_access: PropTypes.bool,
    notes: PropTypes.string,
    appstore_receipt: PropTypes.string,
    appstore_receipt_json: PropTypes.shape({
      product_id: PropTypes.string,
      expires_date: PropTypes.string
    })
  }),
  handlers: PropTypes.shape({
    handleSubmit: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    handleAdminChange: PropTypes.func.isRequired,
    handleBMAccessChange: PropTypes.func.isRequired,
    handlePlanPeriodChange: PropTypes.func.isRequired,
    handleAccessLevelChange: PropTypes.func.isRequired,
    handleSuccessClose: PropTypes.func.isRequired,
    handleAlertDeleteClose: PropTypes.func.isRequired,
    handleAlertDeleteOpen: PropTypes.func.isRequired,
    handleUnlockUser: PropTypes.func.isRequired,
    handleNewsletterChange: PropTypes.func.isRequired,
    handleLoginUser: PropTypes.func.isRequired,
    handleFieldChange: PropTypes.func.isRequired,
    handleVerifyAppStoreReceipt: PropTypes.func.isRequired,
    handleFulfillAccountDeletionRequest: PropTypes.func.isRequired
  }).isRequired,
  register: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isAddUser: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.string,
  admin: PropTypes.bool,
  bmAccess: PropTypes.bool,
  accessLevel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  planPeriod: PropTypes.string,
  verified: PropTypes.bool,
  openAlertDelete: PropTypes.bool,
  newsletter: PropTypes.bool,
  openSuccess: PropTypes.bool,
  isRequestedAccountDeletion: PropTypes.bool,
  errorGettingAccountDeletionRequest: PropTypes.shape({})
};

UserForm.defaultProps = {
  data: {},
  admin: false,
  bmAccess: false,
  accessLevel: null,
  planPeriod: '',
  verified: false,
  newsletter: false,
  openAlertDelete: false,
  openSuccess: false,
  isError: false,
  error: null,
  isAddUser: false,
  isRequestedAccountDeletion: undefined,
  errorGettingAccountDeletionRequest: undefined
};

export default UserForm;
