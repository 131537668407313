import React from 'react';
import PropTypes from 'prop-types';
import MessageBox from '~components/forms/MessageBox';
import TextAreaInput from '~components/form-inputs/TextAreaInput';
import ButtonSubmit from '~components/form-buttons/ButtonSubmit';

const AdminUserPremiumCheckForm = ({
  handlers: { handleSubmit, onSubmit },
  register,
  isLoading,
  isError,
  error
}) => (
  <form onSubmit={handleSubmit(onSubmit)}>
    {isError && <MessageBox text={error} isError />}
    <TextAreaInput name="usernames" label="Usernames" inputRef={register} />
    <ButtonSubmit disabled={isLoading}>Check all of them</ButtonSubmit>
  </form>
);

AdminUserPremiumCheckForm.propTypes = {
  handlers: PropTypes.shape({ handleSubmit: PropTypes.func, onSubmit: PropTypes.func }).isRequired,
  register: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isError: PropTypes.bool,
  error: PropTypes.string
};
AdminUserPremiumCheckForm.defaultProps = {
  isLoading: false,
  isError: false,
  error: undefined
};

export default AdminUserPremiumCheckForm;
