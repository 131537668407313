import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { getPriceTextByPlanIdAndPeriod } from '~utils/products';

const useStyles = makeStyles(() => ({
  noSubscription: {
    fontStyle: 'italic',
    color: '#666'
  }
}));

const SubscriptionDescription = ({ subscription_plan_id, subscription_plan_period }) => {
  const classes = useStyles();
  const priceText = useMemo(
    () => getPriceTextByPlanIdAndPeriod(subscription_plan_id, subscription_plan_period),
    [subscription_plan_id, subscription_plan_period]
  );

  if (!priceText) {
    return <span className={classes.noSubscription}>No subscription</span>;
  }

  return <span>{priceText}</span>;
};
SubscriptionDescription.propTypes = {
  subscription_plan_id: PropTypes.number,
  subscription_plan_period: PropTypes.string
};
SubscriptionDescription.defaultProps = {
  subscription_plan_id: null,
  subscription_plan_period: null
};

export default SubscriptionDescription;
